<template>

    <router-link v-if="$route.name !== (to.toLowerCase())"
                 :to="'/'+$i18n.locale+'/'+(to.toLowerCase().replace(/ /gm,''))">
        <slot/>
    </router-link>
    <a v-else href="#app" uk-scroll>
        <slot/>
    </a>

</template>


<script>

export default {
    name: 'LinkObject',
    props: {
        to: String,
    }
}

</script>


<style lang="scss" scoped>

</style>
