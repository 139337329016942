<template>
    <div class="award">

        <div class="uk-grid-small uk-flex uk-flex-center uk-text-center uk-flex-middle" uk-grid>
            <div class="uk-width-auto">
                <img alt="Leaves Left" class="coronal" src="../assets/images/leaves_left.svg" uk-img>
            </div>

            <div class="uk-width-auto">
                <div class="message-distance"/>
                <div class="category" v-html="event"></div>
                <div class="message" v-html="price"></div>
                <div class="event" v-html="product"></div>
            </div>

            <div class="uk-width-auto">
                <img alt="Leaves Right" class="coronal" src="../assets/images/leaves_right.svg" uk-img>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    name: 'Award',
    props: {
        price: String,
        event: String,
        product: String,
    },
    data() {
        return {
            coronalWidth: 60,
        };
    },
}

</script>


<style lang="scss" scoped>

.coronal {
    width: 60px;
}

@media (max-width: 374px) {
    .coronal {
        width: 50px;
    }
}

@media (max-width: 354px) {
    .coronal {
        display: none;
    }
}

.message-distance {
    margin-top: 12px;
}

.struct-distance {
    width: 20px;
}

.category {
    color: #f2f2ff;
    opacity: 0.5;
    line-height: 1.14286;
    letter-spacing: .007em;
    text-transform: capitalize;
}

.event {
    color: #f2f2ff;
    opacity: 0.35;
    margin-top: 5px;
    line-height: 1.14286;
}

.message {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #fff;
}

</style>
