<template>
    <div class="steps">

        <SiteHeadline :headline="headline" :message="message" :switch-headline="switchHeadline"/>
        <div class="headline-item-distance"/>

        <div
            :class="'uk-grid uk-grid-match uk-flex uk-flex-center '+width"
            uk-grid>

            <div v-for="(step,key) in steps" :key="step.title">
                <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding">
                    <FeatureItem
                        :headline=" (disableSteps ? '' : ((key+1) + '. '))+(step.translate ? $t(step.title) : step.title)"
                        :icon="step.icon"
                        :message="step.translate ? $t(step.message) : step.message"/>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import FeatureItem from "./FeatureItem";
import SiteHeadline from "./SiteHeadline";

export default {
    name: 'Steps',
    components: {SiteHeadline, FeatureItem},
    props: {
        steps: Array,
        headline: String,
        message: String,
        switchHeadline: Boolean,
        disableSteps: Boolean,

        width: {
            type: String,
            default: 'uk-child-width-1-3@m uk-child-width-1-1',
        }
    },
}

</script>


<style lang="scss" scoped>

.reference-icon {
    margin-bottom: 30px;
}

.reference-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #f2f2ff;
    opacity: 0.5;

    &.bold {
        font-weight: 500;
        font-size: 22px;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.reference-value {
    color: #f2f2ff;
    line-height: 1.14286;
    letter-spacing: .007em;
    text-transform: capitalize;
    font-size: 48px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

</style>
