<template>
    <span/>
</template>


<script>

export default {
    name: 'VideoLoop',
    props: {
        id: String,
    },
    mounted() {
        const video = document.getElementById(this.id);

        /*
        setTimeout(function () {
            if (video && video.paused) {
                video.play();
            }
        }, 150);
         */

        video.addEventListener('ended', () => {
            video.currentTime = 0.05;
            video.play();
        });
    }
}

</script>


<style lang="scss" scoped>

</style>
