<template>
    <div class="site-width uk-animation-fade single">

        <Seo :canonical="'/'+$t('tshirts').toLowerCase()" :title="$t('tshirts')"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline
            :headline="$t('tshirts')"
            message="Vielen Dank, dass Du Teil der Kampagne Cancel Cancer bist!"
            has-button
            is-main>

            <div>
                <div class="headline-message-distance"/>

                <FeatureItem
                    class="uk-text-left"
                    message='Gemeinsam mit Dir möchten wir auf die Wichtigkeit der Kinderkrebsforschung aufmerksam machen.<br/><br/>

In Deutschland erkranken jährlich circa 2.000 Kinder und Jugendliche an Krebs – die häufigsten
Formen sind Blutkrebs, Tumore des Lymphgewebes sowie Hirntumore. Aufgrund der geringen
Fallzahlen sind die Mittel und die öffentliche Aufmerksamkeit für die Erforschung dieser
Erkrankungen begrenzt. Die Kinderkrebsforschung ist also in besonderem Maße auf die
Unterstützung durch Spenden angewiesen. Darauf möchten wir aufmerksam machen.<br/><br/>

Hierfür brauchen wir <b>Deine Unterstützung</b>!<br/><br/>

Wir freuen uns, wenn Du Dein Foto mit dem Shirt auf Instagram und Co. postest und den Satz
"Kind sein bedeutet für mich…" vervollständigst. Weise in Deinem Beitrag bitte auf unsere
Spendenaktion auf <b>www.cancelcancer.de</b> hin.<br/><br/>

Bitte verlinke <b>cancelcancer_cc</b> auf Deinem Bild und verwende die Hashtags
<b>#cancelcancer #berlinale #camcore #diefilmpiraten #flyeralarm</b><br/><br/>

Gemeinsam für eine Kindheit ohne Krebs.'/>


                <div class="uk-margin-medium-top"/>
                <Button is-large is-max mode="primary" has-action :action="copyTemplate">
                    <Translate translation-key="copy.template"/>
                </Button>

            </div>

        </SiteHeadline>


        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../../components/SiteHeadline.vue";
import Seo from "../../components/Seo.vue";
import FeatureItem from "@/components/FeatureItem.vue";
import Button from "@/components/Button.vue";
import Translate from "@/components/Translate.vue";

export default {
    name: 'TShirts',
    components: {
        Translate, Button,
        FeatureItem,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            loading: false,
        };
    },
    created() {
        if (this.getMode !== "cancelcancer") {
            this.routeTo("")
        }
    },
    methods: {
        copyTemplate() {
            const text = "Kind sein bedeutet für mich ... @cancelcancer_cc #cancelcancer #berlinale #camcore #diefilmpiraten #flyeralarm cancelcancer.de";

            this.$copyText(text)
        },
    },
}

</script>


<style lang="scss" scoped>

</style>
