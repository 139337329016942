<template>
    <div class="commercial site-width uk-animation-fade">

        <Seo :description="$t('commercialsDescription')" :title="$t('commercialsTitle')" canonical="/commercials"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t('commercial')" :message="$t('commercialsHeadline')"/>
        <div class="headline-message-distance"/>

        <HighlightVideoText :service-items="serviceItems"/>

        <div class="section-distance-new"/>
        <Steps :headline="$t('servicesStepsMessage')" :message="$t('servicesStepsHeadline')" :steps="getConfig.commercialSteps"
               disable-steps
               switch-headline/>


        <div class="section-distance-new"/>
        <SiteHeadline :headline="$t('referencesMessageCommercial')" :message="$t('references')"
                      switch-headline/>
        <div class="headline-message-distance"/>
        <ul class="js-filter uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center" uk-grid>
            <VideoHeadlineItem v-for="(product, key) in references" :id="product.id+''" :key="key"
                               :data-category="product.category"
                               :headline="$t(product.category)"
                               :isCampaign="product.video_link === ''"
                               :itemKey="key"
                               :logo="product.logo ?getBaseUrl+'/api/storage/app/'+product.logo : ''"
                               :message="product.name"
                               :route="product.video_link === '' ? '/'+$t('references').toLowerCase()+'/'+product.shortname : ''"
                               :shortname="product.shortname"
                               :video="product.video_link"
                               :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview_video"
                               class="item-struct"
                               is-max
            />
        </ul>

        <div class="section-distance-new"/>
        <CustomersList :customers="customers" :headline="$t('ourCustomersMessage')" :message="$t('ourCustomers')"
                       switch-headline/>

        <div v-if="false" class="section-distance-new"/>
        <NumberReferences v-if="false"/>


        <div class="last-button-distance"/>
        <LastHeadlineButton :buttonMessage="$t('contact')" :headline="$t('createYourOwnStory')" :route="'contact'"
                            :subMessage="$t('freeOffer')"
                            buttonClass="success"/>

        <div class="last-button-distance"/>

        </div>
</template>


<script>

import SiteHeadline from "../components/SiteHeadline";
import Seo from "../components/Seo";
import VideoHeadlineItem from "../components/VideoHeadlineItem";
import HighlightVideoText from "../components/HighlightVideoText";
import Steps from "../components/Steps";
import NumberReferences from "../components/NumberReferences";
import axios from "axios";
import LastHeadlineButton from "@/components/LastHeadlineButton";
import CustomersList from "@/components/CustomersList";

export default {
    name: 'Commercial',
    components: {
        CustomersList,
        LastHeadlineButton,
        NumberReferences,
        Steps,
        HighlightVideoText,
        VideoHeadlineItem,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            serviceItems: [
                {
                    headline: "commercialsMessage",
                    title: "",
                    message: "commercialText",
                    video: "commercial.mp4"
                },
            ],

            references: [],
            customers: [],
        };
    },
    created() {
        this.getReferences();
        this.getCustomers();

        let hash = this.$route.hash;
        if (hash) {
            this.filter = hash.substring(1);
        }
    },
    methods: {
        getCustomers() {
            let http = axios.create({
                timeout: this.$store.state.apiTimeout,
            });

            http.get(this.getBaseUrl + '/api/customers').then(response => {
                this.customers = response.data;

            }).catch(() => {
            });
        },
        getReferences() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/references?category=commercial').then(response => {
                this.references = response.data;
                this.loading = false;

            }).catch(() => {

            });
        },
    },
}
</script>


<style lang="scss" scoped>

.white {
    margin-bottom: 20px
}

.strong-message {
    font-size: 20px !important;
}

</style>
