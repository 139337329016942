<template>
    <span class="text-field">

        <label class="cursor-pointer">
            <div class="uk-grid-small" uk-grid>
                <div class="uk-width-auto">
                    <span v-if="icon" :uk-icon="'icon: '+icon" class="uk-form-icon"/>
                    <input ref="ref"
                           v-validate="rules"
                           :autocomplete="autocomplete"
                           :autofocus="autofocus"
                           :checked="value"
                           :class="classGenerator(name)"
                           :data-vv-as="$t(label)"
                           :disabled="disabled"
                           :name="name"
                           :placeholder="placeholder ? placeholder : (icon === 'search' ? $t('search') : '')"
                           :title="title"
                           :uk-tooltip="tooltip ? 'title:'+tooltip : ''"
                           type="checkbox"
                           v-on:change="onChange"
                           v-on:input="updateValue($event.target.value)"
                           v-on:keyup.enter="onEnter"
                    >
                </div>
                <div class="uk-width-expand">
                     <LabelWithError v-if="label" :class="(isLabelHidden ? ' uk-hidden' : '')"
                                     :error="errors.first(name)"
                                     :label="$t(label) + (isOptional ? ' ('+$t('optional')+')' : '')"/>
                </div>
            </div>
        </label>

    </span>
</template>


<script>

import LabelWithError from "@/components/LabelWithError";

export default {
    name: 'Checkbox',
    components: {LabelWithError},
    props: {
        fieldKey: Number,
        placeholder: String,
        name: String,
        rules: String,
        value: Boolean,
        label: String,
        type: String,
        icon: String,
        disabled: Boolean,
        simple: Boolean,
        validate: Number,
        onEnter: {
            type: Function,
            default: function () {
            },
        },
        onChange: {
            type: Function,
            default: function () {
            },
        },
        onInput: {
            type: Function,
            default: function () {
            },
        },
        title: String,
        onUpdate: {
            type: Function,
            default: function () {
            },
        },
        autocomplete: {
            type: String,
            default: "off"
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        hasPasswordMeter: Boolean,
        tooltip: String,

        actionIcon: String,
        actionFunction: Function,
        actionTooltip: String,
        isDark: Boolean,
        isHidden: Boolean,
        isLabelHidden: Boolean,
        isOptional: Boolean,
    },
    data() {
        return {
            toggle: false,
        }
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.ref.focus();
                }

                this.$emit('setValid', valid, this.fieldKey)
            });
        },
        updateValue(value) {
            value = value === 'true';

            this.$emit('input', value, this.fieldKey)
            this.validation();

            let self = this;
            setTimeout(function () {
                self.onInput();
            }, 50);
        },
        classGenerator(name) {
            let classes = "uk-checkbox";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.simple) {
                classes += " simple";
            }

            if (this.isDark) {
                classes += " dark";
            }

            if (this.actionIcon) {
                classes += " with-icon";
            }

            return classes;
        },
    },
}

</script>


<style lang="scss" scoped>

.uk-checkbox {
    border-radius: $border-radius;
    font-weight: 500;
    font-size: 16px;
    border-color: transparent;

    &.dark {
        &:hover, &:focus {
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $danger-color !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

input {
    &:disabled {
        cursor: not-allowed;
        border-top-radius: $border-radius;
    }
}

.with-icon {
    cursor: auto !important;
}

.uk-checkbox {
    border-radius: $border-radius !important;
}


.uk-checkbox, .uk-radio {
    border-color: #999;
}

.uk-checkbox, .uk-radio {

    &:checked, &:active {
        // background-color: $site-color !important;
        //border-color: $site-background !important;
        background-color: $info-color-hover!important;
    }

}

</style>
