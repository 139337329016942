<template>
    <div class="number-references">

        <SiteHeadline :headline="$t('stats')"
                      :message="$t('referencesMessage')"
                      :switch-headline="switchHeadline"/>
        <div class="headline-item-distance"/>
        <div
            class="uk-grid uk-grid-match uk-child-width-1-4@l uk-child-width-1-2@s uk-child-width-1-1 uk-flex uk-flex-center  uk-text-center"
            uk-grid>
            <div v-for="reference in references" :key="reference.message">
                <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding">
                    <div :uk-icon="'icon:'+reference.icon+'; ratio: 2.5'" class="reference-icon"></div>
                    <div class="reference-text">{{ $t(reference.headline) }}</div>
                    <div class="reference-value uk-margin">{{ reference.value }}</div>
                    <div class="reference-text bold">{{ $t(reference.message) }}</div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";

export default {
    name: 'NumberReferences',
    components: {SiteHeadline},
    props: {
        switchHeadline: Boolean,
    },
    data() {
        return {
            references: [
                {
                    headline: "over",
                    value: 120,
                    message: "productions",
                    icon: "video-camera"
                },
                {
                    headline: "over",
                    value: 86,
                    message: "awards",
                    icon: "star"
                },
                {
                    headline: "moreThan",
                    value: 15,
                    message: "yearsOfExperience",
                    icon: " history"
                },
                {
                    headline: "always",
                    value: 100,
                    message: "percentDedication",
                    icon: "heart"
                },
            ],
        };
    }
}

</script>


<style lang="scss" scoped>

.reference-icon {
    margin-bottom: 20px;
}

.reference-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #f2f2ff;
    opacity: 0.5;

    &.bold {
        font-weight: 500;
        font-size: 20px;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.reference-value {
    color: #f2f2ff;
    line-height: 1.14286;
    letter-spacing: .007em;
    text-transform: capitalize;
    font-size: 38px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

</style>
