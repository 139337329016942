<template>
    <div class="site-width uk-animation-fade">

        <Seo :canonical="'/'+$t('association').toLowerCase()" :title="$t('association')"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main
                      :message="$t('association')"/>
        <div class="headline-message-distance"/>

        <HighlightVideoText toggle-sides :service-items="serviceItems">
            <template v-slot:after-text>

                <div class="uk-margin-medium-top uk-text-center">
                    <a target="_blank" href="/Satzung_Cancel_Cancer_e.V.pdf">
                        <Button mode="primary" is-large>
                            Unsere Vereinssatzung
                        </Button>
                    </a>
                </div>

            </template>
        </HighlightVideoText>



        <div class="section-distance"/>
        <SiteHeadline message="So kam es zur Gründung"/>
        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Im Dezember 2020 haben wir ein Interview von dem bekannten TV Arzt, Doktor Johannes Wimmer gesehen, der gerade seine Tochter im Alter von nur neun Monaten an einem Hirntumor verloren hat. In dem Interview betonte er, dass die Kinder Krebsforschung im besonderen Maße auf private Spenden angewiesen ist.
Als Junge Familienväter hat uns das Thema so berührt, dass wir unsere Köpfe zusammen gesteckt haben, und daraus ist Cancel Cancer entstanden."/>




        <div class="section-distance"/>
        <SiteHeadline message="Wer steckt hinter dem Verein"
                     />
        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Cancel Cancer e.V. wurde von einer gemischten Gruppe von Menschen aus dem Film-, Gastronomie- und Sozialpädagoschen bereich gegründet. Durch unsere breite Vielfalt an Kompetenzen möchten wir die Kinderkrebsforschung auf kreative und moderne Weise unterstützen."/>


        <SubTeam :team="ambassadors"/>


        <div class="section-distance"/>
        <SiteHeadline message="Das haben wir bisher erreicht"/>


        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Mit unserem Verein möchten wir auf die Wichtigkeit der Kinder Krebsforschung aufmerksam machen. Wir möchten das Thema Kinder Krebs mehr in die Öffentlichkeit rücken, denn wir merken immer wieder, dass es doch eher ein Thema ist und nicht wirklich viel darüber gesprochen wird. Wir möchten mit dem Erlös unserer Spenden, die Kinder Krebsforschung unterstützen und ein Thema, welches sich über die letzten Jahre entwickelt hat, ist die Angst Prävention bei Kindern, die unter dem Krebs leiden.
        Unser Ziel ist es mit einem ganzheitlichen Konzept, in den Kinder Krebsklinik zu unterstützen, dass die Kinder spielerisch durch beispielsweise einem Angst einflößenden MRT muss oder auch zur Bestrahlung."/>
        <div class="headline-message-distance"/>

        <ul v-if="false"
            class="js-filter uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center"
            uk-grid>
            <VideoHeadlineItem v-for="(product, key) in references" :id="product.id+''" :key="key"
                               :data-category="product.category"
                               :headline="$t(product.category)"
                               :isCampaign="product.video_link === ''"
                               :itemKey="key"
                               :logo="product.logo ?getBaseUrl+'/api/storage/app/'+product.logo : ''"
                               :message="product.name"
                               :route="product.video_link === '' ? '/'+$t('references').toLowerCase()+'/'+product.shortname : ''"
                               :shortname="product.shortname"
                               :video="product.video_link"
                               :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview_video"
                               class="item-struct"
                               is-max
            />
        </ul>


        <div class=" uk-child-width-1-3@m uk-child-width-1-1 uk-flex-center" uk-grid>
            <div v-for="index in indexes" :key="index">
                <img :src="require('../../assets/'+getMode+'/references/'+index+'.png')"
                     class="image" uk-img/>
            </div>
        </div>


        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../../components/SiteHeadline.vue";
import Seo from "../../components/Seo.vue";
import HighlightVideoText from "@/components/HighlightVideoText.vue";
import SubTeam from "@/components/SubTeam.vue";
import VideoHeadlineItem from "@/components/VideoHeadlineItem.vue";
import Button from "@/components/Button.vue";
import FeatureItem from "@/components/FeatureItem.vue";

export default {
    name: 'Ambassadors',
    components: {
        FeatureItem,
        Button,
        VideoHeadlineItem,
        SubTeam,
        HighlightVideoText,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            indexes: [1, 2, 3, 4, 5],
            loading: false,

            serviceItems: [
                {
                    headline: "vision",
                    title: "",
                    message: "visionText",
                    image: "Initiatoren.png",
                    text: "<b>Initiatoren:</b> Andreas Olenberg, Daniel Littau, Manuel Mohnke und Timo Wentzel."
                },
            ],


            ambassadors: [
                {
                    name: "Timo Wentzel",
                    titleDE: "Vorstands&shy;vorsitzender",
                    avatar: "twentzel.png",
                },
                {
                    name: "Manuel Mohnke",
                    titleDE: "Stellvertrender Vorstands&shy;vorsitzender",
                    video: "mmohnke.mp4",
                },
                {
                    name: "Daniel Littau",
                    titleDE: "Schatzmeister",
                    video: "dlittau.mp4",
                },
                {
                    name: "Björn Leisten",
                    titleDE: "Schriftführer",
                    video: "bleisten.mp4",
                },
                {
                    name: "Andreas Olenberg",
                    titleDE: "Gründungsmitglied",
                    video: "aolenberg.mp4",
                },
                {
                    name: " Vanessa Mohnke",
                    titleDE: "Gründungsmitglied",
                    avatar: "vmohnke.png",
                },
                {
                    name: "Tanja Kreideweis",
                    titleDE: "Gründungsmitglied",
                    avatar: "tkreideweis.png",
                },
                {
                    name: "Aykut Yalcin",
                    titleDE: "Gründungsmitglied",
                    video: "ayalcin.mp4",
                },

            ]
        };
    },
    created() {
        if (this.getMode !== "cancelcancer") {
            this.routeTo("")
        }
    },
}

</script>


<style lang="scss" scoped>

.image {
    border-radius: $border-radius;
}

</style>
