<template>
    <div class="action-headline-mesage uk-animation-slide-bottom-small">

        <div class="headline-struct uk-text-center">
            <div>
                <h2 class="message uk-padding-remove " v-html="headline"/>
            </div>
            <div>
                <div :class="'headline uk-padding-remove '+headlineClass" v-html="message"/>
            </div>
            <div v-if="text && text.length > 0">
                <div class="text" v-html="text"/>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    name: 'ActionHeadlineMessage',
    props: {
        headline: String,
        message: String,
        text: String,
        headlineClass: String,
    }
}

</script>


<style lang="scss" scoped>

.headline {
    color: #ddd;
    font-weight: 400;
    letter-spacing: .007em;
    line-height: 1.4625;

    &.reset-transform {
        text-transform: initial;
    }
}

.message {
    color: $font-color-hover;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    margin-bottom: 10px !important;
}

.headline-struct {
    padding: 0 20px 0 20px;
}

.text {
    margin-top: 25px;
}

</style>
