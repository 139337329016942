<template>
    <div class="team">

        <SiteHeadline :headline="headline" :message="message" :switch-headline="switchHeadline"/>
        <div class="headline-item-distance"/>

        <div
            class="uk-grid uk-flex-center uk-grid-match uk-child-width-1-6@xl uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-3"
            uk-grid>

            <div v-for="item in team" :key="item.name">
                <div class="uk-card uk-card-alternative uk-height-1-1 uk-dark-card-hovered">
                    <div class="video-struct-item uk-width-1-1">

                        <div class="uk-cover-container uk-animation-fade">

                            <video v-if="item.video" :id="'person-'+item.name" class="uk-width-1-1 video-item"
                                   muted
                                   oncontextmenu="return false;" playsinline uk-video="autoplay: inview">
                                <source :src="require('../assets/videos/'+item.video)"
                                        type="video/mp4">
                            </video>
                            <VideoLoop v-if="item.video" :id="'person-'+item.name"/>

                            <img v-if="item.avatar"
                                 class="image"
                                 :src="require('../assets/'+getMode+'/ambassadors/'+item.avatar)"/>

                        </div>
                    </div>

                    <div class="uk-text-center uk-card-body uk-padding-xsmall">
                        <div class="message" v-html="item.name"></div>
                        <div class="headline" v-html="item['title'+$i18n.locale.toUpperCase()]"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";
import VideoLoop from "@/components/VideoLoop.vue";

export default {
    name: 'SubTeam',
    components: {VideoLoop, SiteHeadline},
    props: {
        team: Array,
        headline: String,
        message: String,
        switchHeadline: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.reference-icon {
    margin-bottom: 30px;
}

.reference-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #f2f2ff;
    opacity: 0.5;

    &.bold {
        font-weight: 500;
        font-size: 22px;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.reference-value {
    color: #f2f2ff;
    line-height: 1.14286;
    letter-spacing: .007em;
    text-transform: capitalize;
    font-size: 48px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.name {
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
}

.title {
    margin-top: 15px;
}

.uk-comment-avatar {
    max-height: 180px;
}

.padding {
    padding: 20px;
}

.headline {
    color: #f2f2ff;
    opacity: 0.5;
    line-height: 1.14286;
    letter-spacing: .007em;
    text-transform: capitalize;
    font-size: 18px;
}

.message {
    margin-bottom: 5px !important;

    font-weight: 500;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #f2f2ff;
    font-size: 18px;
}

@media (max-width: $breakpoint-small) {
    .headline {
        font-size: 16px;
    }

    .message {
        font-size: 16px;
    }
}

.uk-card-body {
    padding: 30px;
}

.email {
    margin-top: 12px;
    font-size: 18px;
}

.uk-margin-xsmall-right {
    margin-right: 3px;
}

.image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

</style>
