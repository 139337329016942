<template>
    <div>

        <div class="label">
            <div v-if="error">
                <div class="input-error" v-html="removeSentenceEndingChar()+ '.'"/>
            </div>
            <div v-else>
                <span v-html="label"/>{{ required ? '*' : '' }}
                <slot/>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    name: 'LabelWithError',
    props: {
        label: String,
        error: String,
        required: Boolean,
    },
    methods: {
        removeSentenceEndingChar() {
            if (this.label.endsWith(".")) {
                const parsedLabel= this.label.slice(0, -1)
                return this.error.replace(this.label, parsedLabel);
            }

            return this.error;
        },
    }
}
</script>


<style lang="scss" scoped>

.label {
    margin-bottom: 8px;
    font-size: $site-font-size-small;
}

.input-error {
    color: $danger-color;
}

</style>
