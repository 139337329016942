<template>
    <span class="text-field">

        <LabelWithError v-if="label" :class="(isLabelHidden ? ' uk-hidden' : '')"
                        :error="errors.first(name)"
                        :label="$t(label) + (isOptional ? ' ('+$t('optional')+')' : '')" :required="required"/>

        <div :class="'uk-inline uk-width-1-1' + (isHidden ? ' uk-hidden' : '')">
            <span v-if="icon" :uk-icon="'icon: '+icon" class="uk-form-icon"/>
            <input ref="ref"
                   v-validate="rules"
                   :autocomplete="autocomplete"
                   :autofocus="autofocus"
                   :class="classGenerator(name)"
                   :data-vv-as="$t(label)"
                   :disabled="disabled"
                   :name="name"
                   :placeholder="placeholder ? placeholder : (icon === 'search' ? $t('search') : '')"
                   :title="title"
                   :type="(toggle ? 'text' : (type ? type : 'text'))"
                   :uk-tooltip="tooltip ? 'title:'+tooltip : ''"
                   :value="value"
                   v-on:change="onChange"
                   v-on:input="updateValue($event.target.value)"
                   v-on:keyup.enter="onEnter"
            />

            <div v-if="type==='password'" :uk-icon="'icon:'+ (toggle ? 'eye-closed' : 'eye')"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 v-on:click="toggle= !toggle"/>

            <div v-if="icon==='search' && value && value.length>0"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 uk-icon="close"
                 v-on:click="updateValue($event.target.value)"/>

            <div v-if="actionIcon" :uk-icon="'icon:'+ actionIcon" :uk-tooltip="'title:'+actionTooltip"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 v-on:click="actionFunction"/>
        </div>

    </span>
</template>


<script>

import LabelWithError from "@/components/LabelWithError";

export default {
    name: 'TextField',
    components: {LabelWithError},
    props: {
        fieldKey: Number,
        placeholder: String,
        name: String,
        rules: String,
        value: String,
        label: String,
        type: String,
        icon: String,
        disabled: Boolean,
        simple: Boolean,
        validate: Number,
        required: Boolean,
        onEnter: {
            type: Function,
            default: function () {
            },
        },
        onChange: {
            type: Function,
            default: function () {
            },
        },
        onInput: {
            type: Function,
            default: function () {
            },
        },
        title: String,
        onUpdate: {
            type: Function,
            default: function () {
            },
        },
        autocomplete: {
            type: String,
            default: "off"
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        hasPasswordMeter: Boolean,
        tooltip: String,

        actionIcon: String,
        actionFunction: Function,
        actionTooltip: String,
        isDark: Boolean,
        isHidden: Boolean,
        isLabelHidden: Boolean,
        isOptional: Boolean,
    },
    data() {
        return {
            toggle: false,
        }
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.ref.focus();
                }

                this.$emit('setValid', valid, this.fieldKey)
            });
        },
        updateValue(value) {
            this.$emit('input', value, this.fieldKey)
            this.validation();

            let self = this;
            setTimeout(function () {
                self.onInput();
            }, 50);
        },
        classGenerator(name) {
            let classes = "uk-input dark-input";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.simple) {
                classes += " simple";
            }

            if (this.isDark) {
                classes += " dark";
            }

            if (this.actionIcon) {
                classes += " with-icon";
            }

            return classes;
        },
    },
}

</script>


<style lang="scss" scoped>

.reveal {
    pointer-events: initial !important;
}

.uk-input {
    border-radius: $border-radius;

    height: 50px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $border-color !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $border-color !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $danger-color !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

input {
    &:disabled {
        cursor: not-allowed;
        border-top-radius: $border-radius;
    }
}

.with-icon {
    cursor: auto !important;
}

</style>
