<template>
    <div class="contact site-width uk-animation-fade">
        <Seo :title="$t('contactTitle')" canonical="/contact"/>
        <div class="menu-headline-distance"/>

        <SiteHeadline is-main :headline="$t('contact')"
                      :message="$t('contact.description')"/>
        <div class="headline-item-distance"/>
        <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding uk-text-center site-width single">

            <div
                class="uk-grid-small uk-child-width-1-3@s uk-child-width-1-1 uk-flex-center uk-grid-match uk-flex uk-flex-middle"
                uk-grid>
                <div v-for="option in contactOptions" :key="option.name">
                    <a :href="'#'+option.id" uk-scroll="offset: 30">
                        <CardSelection :icon="option.icon" :name="$t(option.name)" is-source-icon/>
                    </a>
                </div>
            </div>

        </div>

        <div v-if="getMode !== 'cancelcancer'">
            <span id="contact-form"/>
            <div class="section-distance"/>
            <SiteHeadline :headline="$t('contactFormular')"
                          :message="$t('contactFormularMessage')"/>
            <div class="headline-item-distance"/>

            <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
                <ContactFormular/>
            </div>
        </div>

        <span id="contact-email"/>
        <div class="section-distance"/>
        <EmailContact/>

        <span id="contact-telephone"/>
        <div class="section-distance"/>
        <TelephoneContact/>

        <div class="section-distance"/>
        <SiteHeadline :headline="$t('ourLocation')"
                      :message="$t('ourLocationsMessage')"/>
        <div class="headline-item-distance"/>


        <div
            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding uk-text-center site-width single">
            <div class="available-number uk-grid-collapse uk-flex-center uk-grid-match uk-flex uk-flex-middle"
                 uk-grid>


                <div v-for="location in getConfig.locations" :key="location.name"
                     class="uk-width-1-1">

                    <div class="uk-text-center">
                        <img :alt="location.name+' Logo'" :src="require('../assets/images/'+(location.logo))"
                             class="city-logo"
                             uk-img/>

                        <div class="location-name">{{ location.name }}</div>
                        <div :uk-tooltip="'title:'+$t('copyToClipboard')"
                             class="cursor-pointer"
                             v-on:click="$copyText(location.contact + ' '+ location.street + ' ' + location.zip + ' ' + location.city)">
                            <div class="location-address name">{{ location.contact }}</div>
                            <div class="location-address">{{ location.street }}</div>
                            <div class="location-address">{{ location.zip }} {{ location.city }}</div>
                        </div>

                        <span v-if="false">
                        <span v-for="contact in location.contacts" :key="contact.val">
                            <div
                                class="uk-margin-medium-top uk-grid-collapse uk-flex-center uk-grid-match uk-flex uk-flex-middle"
                                uk-grid>
                                <div>
                                    <a :href="contact.type+':'+(($t(contact.val)).trim())">
                                        <span :uk-icon="'icon:'+contact.icon+'; ratio: 1.3'"></span>
                                    </a>
                                </div>
                                <div>
                                    <a :href="contact.type+':'+(($t(contact.val)).trim())" class="padding-left-small">
                                        <span class="location-address padding-left-small">{{ $t(contact.val) }}</span>
                                    </a>
                                </div>
                            </div>
                            <a :href="contact.type+':'+(($t(contact.val)).trim())"
                               class="uk-button uk-button-default address-button">
                                {{ $t(contact.buttonMessage) }}
                            </a>

                            <div class="respond-info">
                                <span v-if="contact.type ==='tel'">
                                    <TelephoneInformation/>
                                </span>
                                <span v-else>
                                    {{ $t('weWillRespondShortly') }}
                                </span>
                            </div>

                        </span>

                        <div class="available-info">
                            {{ $t('monday') }} {{ $t('to') }} {{ $t('friday') }}<br/>
                            {{ $t('availableTimeMessage') }}
                        </div>
                              </span>


                    </div>
                </div>
            </div>
        </div>

        <div v-if="getMode !== 'cancelcancer'" class="last-button-distance"/>
        <LastHeadlineButton  v-if="getMode !== 'cancelcancer'"  :buttonMessage="$t('aboutUs')" :headline="$t('historyAndVision')" :route="'aboutUsLink'"
                            buttonClass="primary"/>

        <div class="last-button-distance"/>

    </div>
</template>

<script>

import SiteHeadline from "../components/SiteHeadline";
import Seo from "../components/Seo";
import ContactFormular from "../components/ContactFormular";
import TelephoneContact from "../components/TelephoneContact";
import TelephoneInformation from "@/components/TelephoneInformation";
import EmailContact from "@/components/EmailContact";
import LastHeadlineButton from "@/components/LastHeadlineButton";
import CardSelection from "@/components/CardSelection";

export default {
    name: 'Contact',
    components: {
        CardSelection,
        LastHeadlineButton,
        EmailContact,
        TelephoneInformation,
        TelephoneContact,
        ContactFormular,
        Seo,
        SiteHeadline,
    },
    data() {
        return {
            contactOptions: [
                {name: "contactFormular", icon: "file-text", id: "contact-form"},
                {name: "email", icon: "mail", id: "contact-email"},
                {name: "telephone", icon: "receiver", id: "contact-telephone"},
            ],
        };
    },
    created() {
        if (this.getMode === 'cancelcancer') {
            this.contactOptions = [
                {name: "email", icon: "mail", id: "contact-email"},
                {name: "telephone", icon: "receiver", id: "contact-telephone"},
            ];
        }
    },
}

</script>


<style lang="scss" scoped>

.city-logo {
    max-height: 130px;
}

.location-address {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.4;
    letter-spacing: .007em;
    font-size: 18px;

    &.name {
        font-size: 22px;
        font-weight: 500;
    }
}

.available-info {
    margin-top: 10px;
    font-size: 15px;
}

.location-name {
    line-height: 1.0625;
    font-weight: 500;
    letter-spacing: -.009em;
    margin-top: 15px !important;
    margin-bottom: 20px !important;
    font-size: 22px;
}

.address-button {
    margin-top: 10px;
    font-size: 16px !important;
    font-weight: 500 !important;
    min-width: 190px;
}

</style>
