<template>
    <div class="video-modal">

        <div :id="modalId" class="uk-flex-top" uk-modal>
            <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                <button class="uk-modal-close-outside" type="button" uk-close/>
                <iframe v-if="isYoutube()"
                        :src="'https://www.youtube-nocookie.com/embed/'+getYoutubeId()"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        height="720"
                        uk-responsive
                        uk-video width="1280"/>

                <iframe v-else-if="!isVimeoDirect()"
                        :src="'https://player.vimeo.com/video/'+getVimeoId()"
                        allow="autoplay; fullscreen; picture-in-picture" height="720"
                        uk-responsive
                        uk-video width="1280"/>

                <video v-else
                       :src="videoSrc" class="modal-video"
                       controls
                       controlsList="nodownload noremoteplayback" oncontextmenu="return false;" playsinline
                       uk-video="autoplay: inview"/>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    name: 'VideoModal',
    props: {
        modalId: String,
        videoSrc: String,
    },
    mounted() {
        const modalElement = document.getElementById(this.modalId);

        modalElement.addEventListener("beforeshow", function () {
            let videoList = document.getElementsByTagName("video");
            for (let i = 0, j = videoList.length; i < j; i++) {
                let video = videoList[i];
                if (video && !video.paused) {
                    video.pause();
                }
            }
        });

        modalElement.addEventListener("beforehide", function () {
            let videoList = document.getElementsByTagName("video");
            for (let i = 0, j = videoList.length; i < j; i++) {
                let video = videoList[i];

                if (!video.muted) {
                    continue;
                }

                if (video && video.paused) {
                    video.play();
                }
            }
        });

    },
    methods: {
        getYoutubeId() {
            let parts = this.videoSrc.split("v=");

            return parts[parts.length - 1];
        },
        getVimeoId() {
            let parts = this.videoSrc.split("vimeo.com/");

            let subParts = parts[parts.length - 1].split("/");

            let merged = subParts[0];
            if (subParts[1]) {
                merged += "?h=" + subParts[1];
            }

            return merged;
        },
        isYoutube() {
            if (this.videoSrc.includes('youtube')) {
                return true;
            }

            return false;
        },
        isVimeoDirect() {
            if (this.videoSrc.includes('player.vimeo')) {
                return true;
            }

            return false;
        },
    }
}

</script>


<style lang="scss" scoped>

.uk-modal-dialog {
    background: #000 !important;
}

.modal-video {
    max-height: 90vh;
    min-height: 50vh;
}

</style>
