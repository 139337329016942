<template>
    <div>

        <router-link v-if="to" :to="'/'+$i18n.locale+to">
            <slot/>
        </router-link>
        <div v-else :uk-toggle="'target: '+target" class="cursor-pointer">
            <slot/>
        </div>

    </div>
</template>


<script>

export default {
    name: 'LinkToggleObject',
    props: {
        to: String,
        target: String,
    },
}

</script>


<style lang="scss" scoped>

</style>
