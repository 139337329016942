<template>
    <div class="site-width uk-animation-fade">

        <Seo :canonical="'/'+$t('goals').toLowerCase()" :title="$t('goals')"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main
                      :message="$t('goals')"/>
        <div class="headline-message-distance"/>


        <SiteHeadline
            message="Unser Alltag bei Cancel Cancer/unsere Aufgaben als Verein:<br/>Was machen wir, wo geht das Geld hin, warum machen wir das?"/>
        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Krebs bei Kindern ist ein heikles Thema, mit dem sich viele nicht auseinandersetzen wollen, wenn sie nicht gerade selbst betroffen sind. Dabei ist es ein so wichtiges Thema, das nicht unbeachtet bleiben sollte. Deswegen wollen wir mit Cancel Cancer insbesondere auf die Kinderkrebsforschung aufmerksam machen, die noch in den Kinderschuhen steckt. Forschung ist nunmal mit einem hohen Kostenfaktor verbunden und da Krebs häufiger bei Erwachsenen auftritt, wird dort nun mal mehr geforscht. So kommt es vor, dass Kinder mit Medikamenten, die eigentlich für Erwachsene bestimmt sind, behandelt werden. Damit auch Kinder in Zukunft mit personalisierter Krebsmedizin therapiert werden, haben wir es zu unserer Mission gemacht, die Kinderkrebsforschung, unter anderem mit Spendengeldern, zu unterstützen. Gleichzeitig helfen wir Krankenhäusern, Hospize und speziellen Einrichtungen, die für Kinder mit Krebs ausgelegt sind, sowohl ideell als auch materiell.

<br/><br/>Besonders wichtig ist uns dabei auch der persönliche Austausch mit Betroffenen und Angehörigen, die in der schweren Zeit von der Diagnose an bis zur Beendigung der Therapie, Unterstützung und Beratung brauchen. Wir hören uns gerne die Geschichten der Menschen an und versuchen mit unserem Team Hoffnung zu schenken und Mut zu machen. Dazu gehört unter anderem das Erlernen von Bewältigungsstrategien im Umgang mit den medizinischen Untersuchungen, die für Kinder oft angsteinflößend sind. Wir wollen Krankenhäusern dabei helfen, die Therapien für Kinder so angenehm wie möglich zu gestalten, um diese angstfrei zu überstehen.
Um das Thema Kinderkrebs so ansprechend wie möglich zu gestalten, dass nicht alle wegschauen, versuchen wir auch mit Filmen und dokumentarischen Beiträgen so viele Leute wie möglich für das Thema zu sensibilisieren. Hierfür haben wir viele bekannte Botschafter:innen, die uns beistehen und sich mit uns für das Thema stark machen."/>


        <div class="section-distance"/>
        <SiteHeadline message="Ängste bekämpfen"/>
        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Untersuchungen vor und während der Kinderkrebstherapie sind oft mit Ängsten verbunden - bei den betroffenen Kindern und bei ihren Eltern. Wir möchten helfen, diesen Ängsten ganzheitlich zu begegnen. Durch Aufklärung, konkrete Maßnahmen und mit der Hilfe einer starken Community möchten wir Familien unterstützen und finanzschwachen Krankenhäusern und Kinderkrankenhäusern die Möglichkeit geben, Untersuchungen kindgerechter und angstfreier zu gestalten."/>


        <div class="section-distance"/>
        <SiteHeadline message="Wovor haben Kinder Angst?"/>
        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Bei einer Umfrage in unserer Kinderkrebs-Community unter betroffenen Familien haben sich sehr schnell besondere Angstmomente bei Kindern und Eltern herausgefiltert. Bei der Durchführung von bildgebenden Untersuchungen (MRT, Fluoroskopie und CT) stehen Familien vor besonderen Herausforderungen. Kinder müssen für längere Zeit still in einer engen und lauten Röhre liegen, oftmals müssen vorab Kontrastmittel gespritzt werden.<br/><br/>Gerade bei Tumorerkrankungen
wie Hirntumoren oder Knochenkrebs, bei denen bildgebende Untersuchungen dringend notwendig sind, müssen Kinder immer wieder in diese Situation. Eltern haben Angst vor den Ergebnissen und leiden mit ihren Kindern."/>



        <div class="section-distance"/>
        <SiteHeadline message="Wie können wir helfen?"/>
        <FeatureItem
            class="site-width single uk-text-left uk-margin-medium-top"
            message="Wir wollen helfen Ängste abzubauen und den betroffenen Familien sowie den Krankenhäusern, Hospizen und Einrichtungen zur Seite stehen. Durch einen direkten Austausch mit den Kliniken und Familien wären wir immer nah am tatsächlichen Geschehen. Zusammen mit unseren Expert:innen und Partner:innen sowie unserer starken Community möchte Cancel Cancer e.V. krebskranken Kindern Hoffnung und Zuversicht schenken - in der Forschung, aber auch vor Ort. Ebenso möchten wir noch tiefgreifender der Frage nachgehen: Wie wirken sich Ängste auf die Kinderkrebstherapie aus?"/>




        <div class="section-distance"/>
        <SiteHeadline message="Stimmen der Experten"/>

        <Steps :steps="aboutUsSteps"
               :width="getConfig.aboutUsSteps.length === 2 ? 'uk-child-width-1-2@s uk-child-width-1-1' : 'uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1'"
               disable-steps without-headline/>



        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../../components/SiteHeadline.vue";
import Seo from "../../components/Seo.vue";
import FeatureItem from "@/components/FeatureItem.vue";
import Steps from "@/components/Steps.vue";

export default {
    name: 'goals',
    components: {
        Steps,
        FeatureItem,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            aboutUsSteps: [
                {
                    title: "Prof. Dr. Mathias Heikenwälder",
                    message: "Prof. Dr. Mathias Heikenwälder hat Molekularbiologie und Genetik in Wien studiert. Er ist wissenschaftlicher Direktor des M3 Forschungsinstituts in Tübingen und leitet die Forschungsabteilung Entzündung und Krebs am Deutschen Krebsforschungszentrum in Heidelberg. Seit 2019 ist er gewähltes Mitglied der Nationalen Akademie der Wissenschaften (Leopoldina) und gehört zu den weltweit meist zitierten Wissenschaftlern im Feld Zell-Biologie und Krebs. Für seine herausragenden wissenschaftlichen Leistungen wurde er 2022 mit dem Deutschen Krebspreis geehrt.",
                },
                {
                    "title": "Dr. Hanna Heikenwälder",
                    "message": "Dr. Hanna Heikenwälder hat Molekularbiologie in Lübeck, den USA und Zürich studiert. Sie promovierte am Institut für klinische Chemie und Pathobiochemie der Technischen Universität München (TUM) über die Rolle von Entzündungen bei der Krebsentstehung im Darm. In der Chirurgischen Klinik der Universität Heidelberg erforschte sie personalisierte Therapieansätze zur Behandlung von Bauchspeicheldrüsenkrebs. Zur Zeit ist sie am Universitätsklinikum Tübingen tätig und arbeitet zudem als freie Autorin.",
                },
                {
                    title: "Kommentar",
                    message: "„Bildgebende Untersuchungen sind in der Krebstherapie zwingend notwendig. Jedoch sind diese Untersuchungen für krebskranke Kinder besonders kräftezehrend und angsteinflößend. Die Bereitstellung von Aufklärungsmaterialien wird das Leben der betroffenen Kinder wesentlich verbessern. Kinder haben sehr oft Angst, halten nicht still und müssen daher häufig narkotisiert und intubiert werden. Gerade Narkosen stellen eine große Belastung für Kinder dar, besonders für krebskranke Kinder. Wenn die Untersuchungen regelmäßig stattfinden, hilft es enorm, wenn die Kinder und ihre Familien spielerisch aufgeklärt werden, Ängste genommen werden können und Untersuchungen ohne Narkose stattfinden können. Cancel Cancer e.V. kann mit diesem Projekt Kinderkliniken und Kliniken helfen, Untersuchungen und Therapien für betroffene Kinder und Familien möglichst angstfrei zu gestalten.“",
                },
            ],
        };
    },
    created() {
        if (this.getMode !== "cancelcancer") {
            this.routeTo("")
        }
    },
}

</script>


<style lang="scss" scoped>

.image {
    border-radius: $border-radius;
}

</style>
