<template>
    <div class="feature-item">

        <div v-if="icon" class="icon-distance">
            <span :uk-icon="'icon: '+icon+'; ratio: 2.0'"/>
        </div>
        <div v-if="headline" class="info-headline uk-padding-remove uk-margin-remove">
            {{ headline }}
        </div>
        <hr v-if="headline" class="info-line"/>
        <div class="info-message" v-html="message"/>

    </div>
</template>


<script>

export default {
    name: 'FeatureItem',
    props: {
        headline: String,
        message: String,
        icon: String
    }
}

</script>


<style lang="scss" scoped>

.info-headline {
    color: #f5f5f7;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .007em;

    &:first-letter {
        text-transform: capitalize;
    }

    font-size: 24px;
}

.info-message {
    font-size: 20px;
    line-height: 1.16667;
    font-weight: 500;
    letter-spacing: .009em;
    color: #ddd;
}

.info-line {
    margin-top: 20px;
    margin-bottom: 20px;
}

.icon-distance {
    margin-bottom: 10px;
}

</style>
