<template>
    <div v-if="error" class="uk-animation-fade">

        <InfoBox mode="danger">
            <ErrorMessageString
                :error="error"
                :custom-errors="customErrors"/>
        </InfoBox>

    </div>
</template>

<script>

import ErrorMessageString from "@/components/ErrorMessageString.vue";
import InfoBox from "@/components/InfoBox.vue";

export default {
    name: 'ErrorMessage',
    components: {InfoBox, ErrorMessageString},
    props: {
        error: Error,
        customErrors: {
            code: Number,
            message: String,
        }
    },
    data() {
        return {
            isRateLimitInactive: false,
        }
    },
    methods: {
        setRateLimitActive() {
            this.isRateLimitInactive = false;
        },
        setRateLimitInactive() {
            this.isRateLimitInactive = true;
        },
    },
    computed: {
        customErrorExists() {
            if (!this.error || !this.error.response || !this.customErrors) {
                return false;
            }

            for (let i = 0, j = this.customErrors.length; i < j; i++) {
                if (this.customErrors[i].code === this.error.response.status) {
                    return true;
                }
            }

            return false;
        }
    }
}

</script>

<style lang="scss" scoped>

</style>