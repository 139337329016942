<template>
    <div class="site-width uk-animation-fade single">
        <Seo
            :canonical="'/' + $t('membership').toLowerCase()"
            :title="$t('membership')" />

        <div class="menu-headline-distance" />
        <SiteHeadline
            switch-headline
            is-main
            :message="$t('membership.message')"
            headline="Schön, dass Du Teil unseres gemeinnützigen Vereins Cancel Cancer e.V. werden möchtest."
            has-button>
        </SiteHeadline>
        <div class="headline-message-distance" />

        <div
            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
            <MembershipFormular />
        </div>

        <div class="uk-margin-large-top" />

        <LastHeadlineButton
            buttonMessage="Spenden"
            headline="Möchtest du uns lieber mit einer Spende unterstützen?"
            :route="'donate'"
            buttonClass="primary" />

        <div class="last-button-distance" />
    </div>
</template>

<script>
import Seo from '../../components/Seo.vue';
import SiteHeadline from '@/components/SiteHeadline.vue';
import MembershipFormular from '@/views/cancelcancer/MembershipFormular.vue';
import LastHeadlineButton from '@/components/LastHeadlineButton.vue';

export default {
    name: 'Membership',
    components: {
        LastHeadlineButton,
        MembershipFormular,
        SiteHeadline,
        Seo,
    },
    created() {
        if (this.getMode !== 'cancelcancer') {
            this.routeTo('');
        }
    },
};
</script>

<style lang="scss" scoped></style>
