<template>
    <span v-on:click="callAction">

        <button :uk-tooltip="tooltip? 'title:'+ tooltip : ''" :class="'uk-button' + generateClassesByProperties()">
            <span :class="isLoading ? 'uk-invisible' : ''">
                {{ text }}
                <slot/>
            </span>

            <div v-if="isLoading" class="loading-item">
                <div uk-spinner/>
            </div>
        </button>

    </span>
</template>


<script>

export default {
    name: 'Button',
    props: {
        text: String,
        mode: String,

        isLarge: Boolean,
        isSmall: Boolean,
        isDisabled: Boolean,
        isRound: Boolean,
        isLoading: Boolean,
        isMax: Boolean,
        isSelected: Boolean,
        isMinWidth: Boolean,

        action: Function,
        hasAction: Boolean,

        tooltip: String,
    },
    methods: {
        callAction() {
            if (this.isLoading) {
                return;
            }

            if (!this.hasAction) {
                return;
            }

            this.action();
        },
        generateClassesByProperties() {
            let classes = ''

            if (this.isLarge) {
                classes += ' large'
            }

            if (this.isSmall) {
                classes += ' small'
            }

            if (this.isRound) {
                classes += ' round'
            }

            if (this.isMax) {
                classes += ' max'
            }

            if (this.getMode === 'filmpiraten') {
                classes += ' secondary'
            }

            if (this.isMinWidth) {
                classes += ' min-width'
            }

            if (this.isSelected) {
                classes += ' selected'
            }

            if (this.isLoading) {
                classes += ' loading'
            }

            if (this.isDisabled) {
                classes += ' uk-button-disabled'
            } else {
                classes += ' uk-button-' + this.mode
            }

            return classes
        },
    },
}

</script>


<style lang="scss" scoped>

.uk-button {
    text-transform: initial !important;
    color: #fff !important;
    font-size: 16px;
    transition: border-color 0.2s linear, color 0.2s linear, background-color 0.2s linear, backdrop-filter 0.2s linear !important;

    border-radius: $border-radius;
}

.small {
    font-size: 0.875rem !important;
    line-height: 1.33337;
    padding: 4px 11px 4px 11px;
}

.uk-button-disabled {
    background-color: #999 !important;
    cursor: not-allowed !important;
}

.max {
    width: 100%;
}

.round {
    border-radius: 24px;
}

.uk-button-primary {
    background-color: $info-color !important;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        background-color: $info-color-hover !important;
    }
}

.uk-button-success {
    background: $success-color !important;
    color: #fff;
    border: 1px solid transparent;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        color: #fff;
        border: 1px solid transparent;
        background: $success-color-hover !important;
    }

    &.secondary {
        background: $fp-color !important;

        &.hover,
        &:hover,
        &.active,
        &:active,
        &.focus,
        &:focus {
            background: $fp-color-hover !important;

        }
    }
}

.uk-button-default-alt {
    border: 2px solid #fff;
    background-color: transparent;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        color: #333 !important;
        background-color: #fff;
    }
}

.uk-button-default-dark {
    border: 2px solid #999;

    color: #333 !important;
    background-color: transparent;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        border-color: #333;
        color: #000 !important;
    }

    &.selected {
        border-width: 3px;
        border-color: $info-color;
    }
}

.large {
    font-size: 17px !important;
    padding: 0 28px !important;
    min-height: 50px !important;
    font-weight: 500 !important;
    line-height: 28px;
}

.min-width {
    min-width: 180px;
}

.loading-item {
    margin-top: -30px;
}

.uk-button-default {
    border: 2px solid $default-color !important;
    color: $default-color !important;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(29, 29, 31, 0.1) !important;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        border: 2px solid $default-color-hover !important;
        color: $default-color-hover !important;
    }
}

@media (max-width: 425px) {
    .uk-button {
        padding: 0 20px;
    }
}

</style>
