<template>
    <div class="last-headline-button">

        <div :class="'uk-card uk-width-1-1 uk-text-center' + (isLarge ? ' large' : '')"
             uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200;">

            <div class="headline white" v-html="headline"/>

            <div v-if="buttonMessage">
                <router-link v-if="route.length>0" :to="'/'+$i18n.locale+'/'+$t(route).toLowerCase()">
                    <Button :mode="buttonClass" :text="buttonMessage" is-large is-min-width/>
                </router-link>
            </div>
            <slot/>

            <div v-if="subMessage" class="uk-margin-small-top">
                {{ subMessage }}
            </div>

        </div>

    </div>
</template>


<script>

import Button from "@/components/Button";

export default {
    name: 'LastHeadlineButton',
    components: {Button},
    props: {
        route: String,
        headline: String,
        buttonMessage: String,
        buttonClass: String,
        subMessage: String,
        isLarge: Boolean,
    }
}

</script>


<style lang="scss" scoped>

.headline {
    font-size: 24px;
    margin-bottom: 25px;
}

.large {
    padding: 20px 40px 0 40px !important;
}

h2 {
    margin-bottom: 30px !important;
}

</style>
