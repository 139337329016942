<template>
    <div class="sitenotice site-width single uk-animation-fade">

        <Seo :title="$t('sitenoticeTitle')" canonical="/sitenotice"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t('sitenotice')"
                      :message="$t('sitenoticeMessage')"/>
        <div class="headline-message-distance"/>

        <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding ">

            <span v-for="statement in statements" :key="statement.headline">

                <h2 class="white">
                    <span v-if="statement.disableTranslateHeadline">{{ statement.headline }}</span>
                    <span v-else>{{ $t(statement.headline) }}</span>
                </h2>

                <div class="message">
                    <span v-if="statement.translateMessage" v-html="$t(statement.message)"/>
                    <span v-else v-html="statement.message"/>
                </div>

                <div v-if="statement.headline === 'copyright'" :id="$t('privacy')"/>
                <div class="message-distance"/>
            </span>

            <div class="last-updated">
                {{ $t('lastUpdatedAt') }} <br class="mobile-break"/>{{ convertTimestamp(policyUpdatedAt) }}.
            </div>
        </div>

        <div class="last-section-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../components/SiteHeadline";
import Seo from "../components/Seo";
import UIkit from "uikit";

export default {
    name: 'SiteNotice',
    components: {
        Seo,
        SiteHeadline
    },
    methods: {
        convertTimestamp(timestamp) {
            let date = new Date(timestamp * 1000);

            let dateLang = 'en-US';
            if (this.$i18n.locale === 'de') {
                dateLang = 'de-DE';
            }

            return date.toLocaleDateString(dateLang, {
                weekday: 'long',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).replace(/[^ -~]/g, '');
        },
    },
    mounted() {
        let config = this.getConfig;
        this.statements = config.sitenotice;

        let hashtag = location.hash;
        if (hashtag && (hashtag === '#privacy' || hashtag === '#datenschutz')) {
            setTimeout(function () {
                UIkit.scroll(hashtag, {offset: 50}).scrollTo(hashtag);
            }, 50);
        }
    },
    data() {
        return {
            policyUpdatedAt: 1718310525,
            statements: [],
        };
    }
}
</script>


<style lang="scss" scoped>

.message {
    font-size: 18px;
    display: -webkit-box;
    word-break: break-word;
}

.message-distance {
    margin-top: 50px;
}

.last-updated {
    padding-top: 30px;
}

</style>
