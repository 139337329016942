<template>

    <div
        :class="(getMode==='filmpiraten'?' ':'') + ((selected && !isMobileDevice) ? 'uk-dark-card-selected ' : '') + '  uk-height-1-1 uk-padding-small select-item uk-card cursor-pointer '+ (isMobileDevice ? 'uk-dark-card-select' : 'uk-dark-card-select-hover')">

        <div class="uk-text-center uk-padding-very-small">
            <span :uk-icon="'icon: '+icon+'; ratio: 2'"/>
            <div class="product-name" v-html="name"></div>
        </div>

    </div>


</template>


<script>

import {isMobile} from 'mobile-device-detect';

export default {
    name: 'CardSelection',
    props: {
        selected: Boolean,
        name: String,
        icon: String,
    },
    data() {
        return {
            isMobileDevice: isMobile,
        };
    }
}

</script>


<style lang="scss" scoped>

.uk-dark-card-select {
    transition: background-color .25s linear, box-shadow .25s linear, color .25s linear;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    background-color: none !important;
    border: 1px solid $border-color;
    color: #fff;
}

.uk-dark-card-select-hover {
    transition: background-color .25s linear, box-shadow .25s linear, color .25s linear;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    background-color: none !important;
    border: 1px solid $border-color;
    color: #fff;

    &.hover, &:hover {
        box-shadow: 0 14px 25px rgba(0, 0, 0, 1);
        background-color: $info-color;
        color: #fff !important;

        &.secondary {
            background-color: $fp-color !important;
        }
    }
}

.uk-dark-card-selected {
    background-color: $info-color-hover!important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 1);
    color: #fff !important;

    &.secondary {
        background-color: $fp-color-hover !important;
    }
}

.product-name {
    margin-top: 10px;
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.14286;
    letter-spacing: .007em;
    margin-bottom: 3px !important;
    font-size: 18px;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;

    &:first-letter {
        text-transform: capitalize;
    }
}

@media (max-width: 534px) {
    .product-name {
        -webkit-hyphens: manual;
        -ms-hyphens: manual;
        hyphens: manual;
    }
}

</style>
