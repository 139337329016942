<template>
    <div
        :class="'info-box uk-card uk-card-body ' + mode + (borderless ? ' borderless' : '')+  (medium ? ' uk-padding-medium' : '')+ (slim ? ' uk-padding-slim' : '')">
        <a v-if="closeable" class="uk-alert-close" uk-close/>

        <div class="uk-grid-small" uk-grid>
            <div v-if="icon" class="uk-width-auto">
                <Icon :icon="icon" :ratio="1.5" class="icon"/>
            </div>
            <div class="uk-width-expand">
                <slot/>
            </div>
        </div>


    </div>
</template>

<script>


import Icon from "@/components/Icon.vue";

export default {
    name: 'InfoBox',
    components: {Icon},
    props: {
        mode: {
            type: String,
            default: "info",
        },
        borderless: Boolean,
        icon: String,
        closeable: Boolean,
        slim: Boolean,
        medium: Boolean,
    }
}

</script>


<style lang="scss" scoped>

.uk-alert {
    background: #fff;
}

.info-box {
    border-left: 4px solid;
    border-radius: 0 !important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);

    &.borderless {
        box-shadow: 0 0 0 0 transparent;
    }

    &.danger {
        border-color: $danger-color !important;
    }

    &.success {
        border-color: $success-color !important;
    }

    &.warning {
        border-color: $warning-color !important;
    }

    &.info {
        border-color: $info-color !important;
    }
}

</style>