import { render, staticRenderFns } from "./VideoLoop.vue?vue&type=template&id=04939079&scoped=true"
import script from "./VideoLoop.vue?vue&type=script&lang=js"
export * from "./VideoLoop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04939079",
  null
  
)

export default component.exports