<template>
    <div class="telephone-contact">

        <SiteHeadline v-if="getMode==='cancelcancer'"
                      message="Telefon"/>
        <SiteHeadline v-else
                      :headline="$t('directContact')"
                      :message="$t('directContactMessage')"/>
        <div class="headline-item-distance"/>
        <div
            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding uk-text-center site-width single">
            <div class="available-number uk-grid-collapse uk-flex-center uk-grid-match uk-flex uk-flex-middle cursor-pointer"
                 :uk-tooltip="'title:'+$t('copyToClipboard')"
                 v-on:click="$copyText(getConfig['tel'+$i18n.locale.toUpperCase()] )"
                 uk-grid>
                <div>
                    <span uk-icon="icon: receiver; ratio: 1.3"></span>
                </div>
                <div>
                    <span class="padding-left-small">
                        <span class="location-address padding-left-small">{{ getConfig['tel'+$i18n.locale.toUpperCase()] }}</span>
                    </span>
                </div>
            </div>

            <TelephoneInformation/>

            <div class="available-info">
                {{ $t('monday') }} {{ $t('to') }} {{ $t('friday') }}<br/>
                {{ $t('availableTimeMessage') }}
            </div>
        </div>

    </div>
</template>


<script>

import TelephoneInformation from "./TelephoneInformation";
import SiteHeadline from "./SiteHeadline";

export default {
    name: 'TelephoneContact',
    components: {SiteHeadline, TelephoneInformation},
}

</script>


<style lang="scss" scoped>

.location-address {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.4;
    letter-spacing: .007em;
    font-size: 22px;

    &.name {
        font-size: 22px;
        font-weight: 500;
    }
}

.available-info {
    margin-top: 15px;
    font-size: 16px;
}


.available-number {
    margin-bottom: 15px;
}

</style>
