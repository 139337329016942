<template>
    <div>


        <div v-for="(serviceItem, key) in serviceItems" :key="key">

            <div class="uk-grid-collapse uk-grid-match uk-flex  uk-visible@l" uk-grid>

                <div
                    :class="'uk-width-1-1 uk-text-center uk-animation-fade '+(isEqual ? 'uk-width-1-2 ' : 'uk-width-2-3 ') + ((toggleSides ? (key+1) : key) % 2 === 0 ? '' : ' uk-flex-last')">

                    <div v-if="serviceItem.video"
                         :class="'uk-cover-container uk-height-1-1 ' + (serviceItem.headline === 'socialMedia' ? ' ' : '')">
                        <video v-if="serviceItem.video" :id="'video-text-top-'+key" class="video"
                               muted
                               oncontextmenu="return false;" playsinline uk-video="autoplay: inview">
                            <source :src="require('../assets/'+getMode+'/videos/'+serviceItem.video)" type="video/mp4">
                        </video>
                        <VideoLoop v-if="serviceItem.video" :id="'video-text-top-'+key"/>
                    </div>

                    <div v-if="serviceItem.image">
                        <img :src="require('../assets/'+getMode+'/images/'+serviceItem.image)"
                             class="image"
                             uk-img/>
                        <div class="uk-margin-top" v-if="serviceItem.text" v-html="serviceItem.text"/>
                    </div>


                </div>

                <div
                    :class="isEqual ? 'uk-width-1-2' : 'uk-width-1-3'">
                    <div
                        :class="'services-item-text'+ (serviceItem.headline === 'socialMedia' ? ' socialmedia' : '')">
                        <div v-if="serviceItem.headline"
                             :class="'services-headline uk-margin-remove'+(serviceItem.title ? '' : ' only')"
                             v-html="$t(serviceItem.headline)"></div>
                        <span class="services-title">{{ $t(serviceItem.title) }}</span>

                        <hr v-if="serviceItem.title"/>

                        <span class="services-message" v-html="$t(serviceItem.message)"></span>
                    </div>

                    <slot name="after-text"/>

                </div>
            </div>

            <div class="uk-grid-collapse mobile-struct uk-flex  uk-child-width-1-1 uk-hidden@l"
                 uk-grid>
                <div>
                    <div class="services-item-text-mobile">
                        <div :class="'services-headline uk-margin-remove'+(serviceItem.title ? '' : ' only')"
                             v-html="$t(serviceItem.headline)"></div>
                        <span v-if="serviceItem.title" class="services-title">{{ $t(serviceItem.title) }}</span>
                        <hr/>
                        <span class="services-message" v-html="$t(serviceItem.message)"></span>
                    </div>
                </div>

                <div v-if="serviceItem.video" class="uk-width-1-1 viewport-struct">
                    <div
                        :class="'uk-cover-container uk-height-1-1 media-section'+ (serviceItem.headline === 'socialMedia' ? ' socialmedia' : '')">
                        <video v-if="serviceItem.video" :id="'video-text-'+key"
                               class="video uk-width-1-1"
                               muted
                               oncontextmenu="return false;" playsinline uk-cover="autoplay: inview">
                            <source :src="require('../assets/'+getMode+'/videos/'+serviceItem.video)" type="video/mp4">
                        </video>
                        <VideoLoop v-if="serviceItem.video" :id="'video-text-'+key"/>
                    </div>
                </div>

                <div v-if="serviceItem.image">
                    <img class="image" :src="require('../assets/'+getMode+'/images/'+serviceItem.image)" uk-img/>
                    <div class="uk-margin-top " v-if="serviceItem.text" v-html="serviceItem.text"/>
                </div>
            </div>

            <div v-if="key !== serviceItems.length-1" class="item-distance"/>
        </div>

    </div>
</template>


<script>

import VideoLoop from "@/components/VideoLoop";

export default {
    name: 'HighlightVideoText',
    components: {VideoLoop},
    props: {
        serviceItems: Array,
        toggleSides: Boolean,
        isEqual: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.services-item-text {
    padding-right: 40px;
    padding-left: 40px;

    &.socialmedia {
        padding-left: 0;
    }
}

.services-headline {
    color: #f2f2ff;
    opacity: 0.5;
    line-height: 1.14286;
    letter-spacing: .007em;
    margin-bottom: 10px !important;

    &.only {
        opacity: 1.0;
    }

    font-size: 30px;
}

.services-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #f2f2ff;
}

.services-message {
    color: #ccc;
    line-height: 1.14286;
    letter-spacing: .007em;
    font-size: 20px;
    font-weight: 500;
}

.services-item-text-mobile {
    padding-bottom: 40px;
}

.video {
    max-height: 800px;
    border-radius: $border-radius;
}

.image {
    border-radius: $border-radius;
}

.media-section {
    background-color: #000;


    &.socialmedia {
        background-color: transparent;
    }
}

.viewport-struct {
    height: calc(85vh - 80px - 1px);
}

</style>
