<template>
    <div class="site-width uk-animation-fade">

        <Seo :canonical="'/'+$t('ambassadors').toLowerCase()" :title="$t('ambassadors')"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main
                      headline="Viele Gesichter"
                      :message="$t('ambassadors')"/>
        <div class="headline-message-distance"/>

        <HighlightVideoText :service-items="serviceItems"/>


        <div class="section-distance"/>
        <SubTeam :team="ambassadors"/>

        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../../components/SiteHeadline.vue";
import Seo from "../../components/Seo.vue";
import HighlightVideoText from "@/components/HighlightVideoText.vue";
import SubTeam from "@/components/SubTeam.vue";

export default {
    name: 'Ambassadors',
    components: {
        SubTeam,
        HighlightVideoText,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            loading: false,

            serviceItems: [
                {
                    headline: "commercialsMessage",
                    title: "",
                    message: "commercialText",
                    image: "Initiatoren und Schirmherr.jpeg",
                    text: "Timo Wentzel & Daniel Littau zusammen mit Schirmherrn Nico Hofmann."
                },
            ],

            ambassadors: [
                {
                    avatar: "Amira Pocher.jpg",
                    name: "Amira Pocher",
                    titleDE: "Moderatorin & Influencerin"
                },
                {
                    avatar: "Amy Mußul.png",
                    name: "Amy Mußul",
                    titleDE: "Schauspielerin"
                },
                {
                    avatar: "Frederick Lau.jpg",
                    name: "Frederick Lau",
                    titleDE: "Schauspieler"
                },
                {
                    avatar: "Joris.png",
                    name: "Joris",
                    titleDE: "Musiker"
                },
                {
                    avatar: "Kida Khodr Ramadan.jpg",
                    name: "Kida Khodr Ramadan",
                    titleDE: "Schauspieler"
                },
                {
                    avatar: "Maria Wedig.jpg",
                    name: "Maria Wedig",
                    titleDE: "Schauspielerin"
                },
                {
                    avatar: "Nico Hofmann.jpg",
                    name: "Nico Hofman",
                    titleDE: "Filmproduzent"
                },
                {
                    avatar: "Sabin Tambrea.jpg",
                    name: "Sabin Tambrea",
                    titleDE: "Schauspieler"
                },
                {
                    avatar: "Sven Martinek.png",
                    name: "Sven Martinek",
                    titleDE: "Schauspieler"
                },
                {
                    avatar: "Timur Bartels.jpg",
                    name: "Timur Bartels",
                    titleDE: "Schauspieler"
                },
                {
                    avatar: "Ulrike Frank.jpg",
                    name: "Ulrike Frank",
                    titleDE: "Schauspieler"
                },

            ]
        };
    },
    created() {
        if (this.getMode !== "cancelcancer") {
            this.routeTo("")
        }
    },
}

</script>


<style lang="scss" scoped>

</style>
