<template>
    <div class="card-title">

        <div v-if="subtitle" :class="'subtitle'+(isDark ? ' dark' : '')" v-html="subtitle"/>
        <div :class="'title'+(isDark ? ' dark' : '')" v-html="title"/>

    </div>
</template>

<script>

export default {
    name: 'ModalTitle',
    props: {
        title: String,
        subtitle: String,
        isDark: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.title {
    color: $site-color-hover;
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 32px;

    &.dark {
        color: $site-background;
    }
}

.subtitle {
    color: $site-color;
    font-weight: 600;
    font-size: 18px;

    &.dark {
        color: $site-background;
    }
}

</style>