<template>
    <div>
        <SiteHeadline :headline="headline" :message="message" :switch-headline="switchHeadline"
                      class="block-headline-distance"/>

        <div uk-grid
            class="section-headline-distance uk-text-center uk-flex uk-flex-middle uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-2 uk-flex uk-flex-center">
            <div v-for="award in awards" :key="award" class="uk-text-center">
                <div class="uk-padding-small">
                    <Award v-if="false" :event="award.event" :price="award.price" :product="award.product"/>

                    <img v-if="true" :alt="award" :src="require('../assets/images/awards/'+award)" class="customer-logo"
                         uk-img>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";
import Award from "@/components/Award";

export default {
    name: 'AwardList',
    components: {Award, SiteHeadline},
    props: {
        headline: String,
        message: String,
        awards: Array,
        switchHeadline: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.section-headline-distance {
    margin-top: 60px;
}

.customer-logo {
    max-height: 160px;
}

</style>
