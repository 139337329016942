<template>
    <div class="imagefilm site-width uk-animation-fade">

        <Seo :description="$t('imagefilmDescription')" :title="$t('imagefilmTitle')" canonical="/imagefilm"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t('imagefilm')" :message="$t('imagefilmHeadline')"/>
        <div class="headline-message-distance"/>

        <HighlightVideoText :service-items="serviceItems" toggle-sides/>

        <div class="section-distance-new"/>
        <Steps :headline="$t('servicesStepsMessage')" :message="$t('servicesStepsHeadline2')" :steps="getConfig.imagefilmSteps"
               disable-steps
               switch-headline/>


        <div class="section-distance-new"/>
        <SiteHeadline :headline="$t('referencesMessageImage')" :message="$t('references')"
                      switch-headline/>
        <div class="headline-message-distance"/>
        <ul class="js-filter uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center" uk-grid>
            <VideoHeadlineItem v-for="(product, key) in references" :id="product.id+''" :key="key"
                               :data-category="product.category"
                               :headline="$t(product.category)"
                               :isCampaign="product.video_link === ''"
                               :itemKey="key"
                               :logo="product.logo ?getBaseUrl+'/api/storage/app/'+product.logo : ''"
                               :message="product.name"
                               :route="product.video_link === '' ? '/'+$t('references').toLowerCase()+'/'+product.shortname : ''"
                               :shortname="product.shortname"
                               :video="product.video_link"
                               :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview_video"
                               class="item-struct"
                               is-max
            />
        </ul>

        <div class="section-distance-new"/>
        <CustomersList :customers="customers" :headline="$t('ourCustomersMessage2')" :message="$t('ourCustomers')"
                       switch-headline/>

        <div v-if="false" class="section-distance-new"/>
        <NumberReferences v-if="false"/>

        <div class="last-button-distance"/>
        <LastHeadlineButton :buttonMessage="$t('contact')" :headline="$t('createYourOwnStory')" :route="'contact'"
                            :subMessage="$t('freeOffer')"
                            buttonClass="success"/>

        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../../components/SiteHeadline.vue";
import Seo from "../../components/Seo.vue";
import VideoHeadlineItem from "../../components/VideoHeadlineItem.vue";
import HighlightVideoText from "../../components/HighlightVideoText.vue";
import Steps from "../../components/Steps.vue";
import NumberReferences from "../../components/NumberReferences.vue";
import axios from "axios";
import LastHeadlineButton from "@/components/LastHeadlineButton.vue";
import CustomersList from "@/components/CustomersList.vue";

export default {
    name: 'Imagefilm',
    components: {
        CustomersList,
        LastHeadlineButton,
        NumberReferences,
        Steps,
        HighlightVideoText,
        VideoHeadlineItem,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            serviceItems: [
                {
                    headline: "imagefilmMessage",
                    title: "",
                    message: "imagefilmText",
                    video: "image.mp4"
                },
            ],

            references: [],
            customers: [],
        };
    },
    created() {
        this.getReferences();
        this.getCustomers();

        let hash = this.$route.hash;
        if (hash) {
            this.filter = hash.substring(1);
        }
    },
    methods: {
        getCustomers() {
            let http = axios.create({
                timeout: this.$store.state.apiTimeout,
            });

            http.get(this.getBaseUrl + '/api/customers').then(response => {
                this.customers = response.data;

            }).catch(() => {
            });
        },
        getReferences() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/references?category=image').then(response => {
                this.references = response.data;
                this.loading = false;

            }).catch(() => {

            });
        },
    },
}
</script>


<style lang="scss" scoped>

.white {
    margin-bottom: 20px
}

.strong-message {
    font-size: 20px !important;
}

</style>
