<template>
    <div class="seo">

        <seo-title :content="title +' - '+getConfig.seoTitle"/>
        <seo-canonical v-if="canonical" :content="'https://'+getConfig.url+canonical"/>

        <div v-if="description">
            <seo-description :content="description"/>
            <seo-google-description :content="description"/>
            <seo-og-description :content="description"/>
            <seo-twitter-description :content="description"/>
        </div>

    </div>
</template>


<script>

export default {
    name: 'Seo',
    props: {
        title: String,
        canonical: String,
        description: String,
    },
}

</script>


<style lang="scss" scoped>

</style>
