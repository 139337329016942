<template>

    <div>
        <div v-if="isLoading" class="video-struct">
            <div class="uk-height-small uk-flex uk-flex-center uk-flex-middle"
                 uk-spinner/>
        </div>
        <div v-else
             :class="isAnimationDisabled? '' : 'uk-animation-fade'">
            <slot/>
        </div>
    </div>

</template>


<script>

export default {
    name: 'LoadingStruct',
    props: {
        isLoading: Boolean,
        isAnimationDisabled: Boolean,
    },
}


</script>

<style lang="scss" scoped>

.spinner {
    color: $font-color;
}

</style>
