<template>
    <div>

        <div :id="id" :class="'uk-modal' + (isExtended ? ' uk-modal-container' : '') + (isFull ? ' uk-modal-full' : '')"
             :uk-modal="isStrict ? 'esc-close: false; bg-close:false;' : ''">
            <div
                :class="(isWithoutDialog? '': 'uk-modal-dialog') + (isDark? '  dark': '')  + (isWithoutBody? '': ' uk-modal-body' ) + (isCentered? ' uk-margin-auto-vertical' :'') + (isMedium ? ' medium' : '')">

                <span v-if="!isWithoutClose && !isStrict">
                    <button v-if="isCloseOutside" class="uk-modal-close-outside" type="button" uk-close/>
                    <button v-else class="uk-modal-close-default" type="button" uk-close/>
                </span>

                <ModalTitle :id="idTitle" v-if="title" :title="title" class="uk-text-center" is-dark/>

                <slot/>

            </div>
        </div>

    </div>
</template>

<script>


import ModalTitle from "@/components/ModalTitle.vue";

export default {
    name: 'Modal',
    components: {ModalTitle},
    props: {
        id: String,
        idTitle: String,
        isExtended: Boolean,
        isMedium: Boolean,
        title: String,
        isCentered: Boolean,
        isWithoutBody: Boolean,
        isCloseOutside: Boolean,
        isFull: Boolean,
        isWithoutClose: Boolean,
        isWithoutDialog: Boolean,
        isDark: Boolean,
        isStrict: Boolean,
    },
}

</script>

<style lang="scss" scoped>

.uk-modal {
    color: $site-background;
}

.uk-modal-dialog {
    border-radius: $border-radius;

    &.dark {
        background-color: #000;
    }

    &.medium {
        width: 800px !important;
    }
}

</style>
