<template>

    <div class="uk-child-width-auto@s uk-child-width-1-1 uk-flex-center" uk-grid>
        <div v-for="link in buttons" :key="link">
            <router-link :to="'/'+$i18n.locale+'/'+$t(link).toLowerCase()">
                <Button :text="$t(link)" is-large is-min-width mode="default"/>
            </router-link>
        </div>
    </div>


</template>


<script>

import Button from "@/components/Button";

export default {
    name: 'PromotionButtons',
    props: {
        buttons: Array,
    },
    components: {Button},
}

</script>


<style lang="scss" scoped>

</style>
