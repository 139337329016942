<template>
    <div id="features" class="new-section-struct site-width">

        <SiteHeadline :headline="headline" :message="message"
                      :switch-headline="switchHeadline" class="uk-margin-bottom"/>

        <div class="section-headline-distance"/>

        <div
            class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center" uk-grid>

            <VideoHeadlineItem v-for="(product, key) in videos" :id="product.id+''" :key="key"
                               :data-category="product.category"
                               :headline="$t(product.category)"
                               :isCampaign="product.video_link === ''"
                               :itemKey="key"
                               :logo="product.logo ?getBaseUrl+'/api/storage/app/'+product.logo : ''"
                               :message="product.name"
                               :route="product.video_link === '' ? '/'+$t('references').toLowerCase()+'/'+product.shortname : ''"
                               :shortname="product.shortname"
                               :video="product.video_link"
                               :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview_video"
                               class="item-struct"
                               is-max
            />

        </div>

        <div class="block-button-distance uk-text-center">
            <router-link :to="'/'+$i18n.locale+'/'+$t('references').toLowerCase()">
                <Button :text="$t('moreReferences')" is-large is-min-width mode="default"/>
            </router-link>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";
import VideoHeadlineItem from "@/components/VideoHeadlineItem";
import Button from "@/components/Button";

export default {
    name: 'VideoList',
    components: {Button, VideoHeadlineItem, SiteHeadline},
    props: {
        videos: Array,
        headline: String,
        message: String,
        switchHeadline: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.video-list {
    padding: 80px 40px 80px 40px !important;
}

.video-showreel {
    opacity: 0.6;
}

.section-headline-distance {
    margin-top: 60px;
}

.block-button-distance {
    margin-top: 50px;

    &.additional-distance {
        margin-top: 100px;
    }
}

.block-headline-distance {
    margin-bottom: 80px;
}

.text-button-distance {
    margin-top: 100px;
}

.title-distance {
    margin-top: 120px;
}

.view-distance {
    height: 12px;
}

.uk-card-dark {
    background: $alternative-color;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-width-very-small {
    width: 70px;
}

#features {
    padding-top: -70px;
}

</style>
