<template>
    <div class="site-width uk-animation-fade">

        <Seo :canonical="'/'+$t('references').toLowerCase()+'/'+item.shortname" :title="item.name"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t(item.category)"
                      :logo="item.logo ?getBaseUrl+'/api/storage/app/'+item.logo : ''"
                      :message="item.name"
        />
        <div class="headline-message-distance"/>

        <div v-if="item['description' + $i18n.locale.toUpperCase()]"
             class="site-width single uk-text-center strong-message"
             v-html="item['description' + $i18n.locale.toUpperCase()]"></div>
        <div v-if="item['description' + $i18n.locale.toUpperCase()]" class="headline-message-distance"/>


        <div v-if="item.shortname.includes('phantomschmerz')">
            <div
                class="uk-grid uk-grid-match uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-1 uk-flex uk-flex-center  uk-text-center"
                uk-grid>
                <div v-for="rating in ratings" :key="rating.message">
                    <a :href="rating.link" rel='nofollow' target="_blank">
                        <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding">
                            <div class="uk-grid-small uk-flex uk-flex-center uk-flex-middle" uk-grid>

                                <div class="uk-width-auto">
                                    <div class="reference-icon" uk-icon="icon: star; ratio: 3.0"></div>
                                </div>

                                <div class="uk-width-1-1 uk-inline">
                                    <div class="rating-numbers">{{ (rating.value).toLocaleString('de-DE') }} /
                                        {{ rating.limit }}
                                    </div>
                                    <div class="rating-text">{{ rating.headline }}</div>
                                </div>

                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="section-distance-new"/>
        </div>

        <div v-if="item.shortname.includes('phantomschmerz')">
            <div class="section-distance-new"/>
            <SiteHeadline :headline="$t('watch')"
                          :message="$t('onAmazon')"/>
            <div class="headline-item-distance"/>

            <div
                class="uk-grid uk-grid-match uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-1 uk-flex uk-flex-center uk-text-center"
                uk-grid>
                <div v-for="rating in amazon" :key="rating.message">
                    <a :href="rating.link" rel='nofollow' target="_blank">
                        <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding">
                            <div class="uk-grid-small uk-flex uk-flex-center uk-flex-middle" uk-grid>

                                <div class="uk-width-auto">
                                    <div :uk-icon="'icon:'+rating.icon+'; ratio: 3.5'" class="reference-icon"></div>
                                </div>

                                <div class="uk-width-1-1 uk-inline uk-margin-top">
                                    <div>
                                        <Button :text="rating.headline" is-large is-max mode="success"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="section-distance-new"/>
        </div>

        <div v-if="item.pictures && item.pictures.length>0">
            <div class="section-distance-new"/>
            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1"
                 uk-slider="center: true; autoplay: true; autoplay-interval: 6000">

                <ul class="uk-slider-items uk-grid uk-grid-match"
                    uk-height-viewport="offset-top: true; offset-bottom: 30">
                    <li v-for="item in item.pictures" :key="'picture'+item" class="uk-width-3-4 picture-height">
                        <div class="uk-cover-container">
                            <img :alt="item.name"
                                 :src="getBaseUrl+'/api/storage/app/'+item.picture" uk-cover>

                        </div>
                    </li>
                </ul>

                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
                   uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
                   uk-slider-item="next"></a>

            </div>
            <div class="item-distance"/>
        </div>


        <ul class="js-filter uk-child-width-1-2@l uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center" uk-grid>
            <VideoHeadlineItem v-for="(product, key) in item.videos" :id="product.id+''" :key="key"
                               :itemKey="key"
                               :message="product.name"
                               :shortname="product.shortname+'-'+key"
                               :video="product.link"
                               :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview"
                               class="item-struct"
                               is-max
            />
        </ul>


        <div v-if="item.shortname.includes('phantomschmerz')">
            <div class="section-distance-new"/>
            <SiteHeadline :headline="$t('filmDetails')"
                          :message="$t('whoMadeIt')"/>
            <div class="headline-item-distance"/>
            <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
                <div class="uk-grid-large uk-child-width-1-2@s uk-child-width-1-1 uk-text-center"
                     uk-grid="masonry: true">
                    <div v-for="detail in details" :key="detail.name">
                        <div class="detail-name">{{ $t(detail.name) }}</div>
                        <div class="detail-value" v-html="detail.value"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="last-button-distance"/>
        <LastHeadlineButton :buttonMessage="$t('moreReferences')" :headline="$t('watchMore')" :route="'references'"
                            buttonClass="default"/>
        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../components/SiteHeadline";
import Seo from "../components/Seo";
import LastHeadlineButton from "../components/LastHeadlineButton";
import VideoHeadlineItem from "../components/VideoHeadlineItem";
import axios from "axios";
import Button from "@/components/Button";

export default {
    name: 'Reference',
    components: {
        Button,
        VideoHeadlineItem,
        LastHeadlineButton,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            item: {
                "id": 0,
                "category": "",
                "name": "",
                "shortname": "",
                "descriptionDE": "",
                "descriptionEN": "",
                "logo": "",
                "preview_video": "",
                "video_link": "",
                "favorite": 0,
                "logo_name": "",
                "preview_video_name": "",
                "sort": 0,
                "videos": "",
                "pictures": ""
            },

            details: [
                {
                    name: "distributionAndProduction",
                    value: "Camcore",
                },
                {
                    name: "director",
                    value: "Andreas Olenberg",
                },
                {
                    name: "script",
                    value: "Andreas Olenberg<br/>Daniel Littau",
                },
                {
                    name: "actors",
                    value: "Sven Martinek<br/>Daniel Littau<br/>Christine Kroop<br/>Katy Karrenbauer<br/>Jessica Boehrs",
                },
                {
                    name: "music",
                    value: "Nicolai Retzlaff",
                },
                {
                    name: "cinematographer",
                    value: "Sebastian Sellner",
                },
                {
                    name: "coProducer",
                    value: "Aykut Yalcin",
                },
            ],

            ratings: [
                {
                    headline: "IMDb",
                    value: 6.9,
                    limit: 10,
                    link: "https://www.imdb.com/title/tt7753296/"
                },
                {
                    headline: "Amazon",
                    value: 4.4,
                    limit: 5,
                    link: "https://www.amazon.de/dp/B07MFH8TXQ/ref=cm_sw_em_r_mt_dp_U_ERPzEbP58HVW0",
                },
                {
                    headline: "Filmstarts",
                    value: 3.5,
                    limit: 5,
                    link: "http://www.filmstarts.de/kritiken/265356.html",
                },
            ],

            amazon: [
                {
                    icon: "play",
                    headline: "Amazon Prime",
                    link: "https://www.amazon.de/gp/video/detail/B09T76PHR4"
                },
                /*
                {
                    icon: "cart",
                    headline: "Amazon Kauf",
                    link: "https://www.amazon.de/dp/B07MFH8TXQ"
                },
                 */
            ],
        };
    },
    created() {
        let name = this.$route.params.id;
        this.getReference(name);
    },
    methods: {
        getReference(name) {
            let http = axios.create({
                timeout: this.$store.state.apiTimeout,
            });

            this.loading = true;
            http.get(this.getBaseUrl + '/api/references/name/' + encodeURIComponent(name)).then(response => {
                let item = response.data;

                item.videos = JSON.parse(item.videos);
                item.pictures = JSON.parse(item.pictures);

                this.item = item;

            }).catch(() => {

            });
        },
    }
}
</script>


<style lang="scss" scoped>

.picture-height {
    height: calc(80vh - 80px - 1px);
    max-height: 1280px;
}

.rating-numbers {
    font-size: 28px;
    font-weight: 500;
}

.rating-text {
    font-size: 20px;
}

.detail-name {
    font-size: 16px;
}

.detail-value {
    font-size: 20px;
    font-weight: 500;
}

</style>
