import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage'

Vue.use(Vuex);
Vue.use(VueLocalStorage)

export default new Vuex.Store({
    state: {
        language: "de",
        baseUrl: "",
        cookieconsent: "",
        mode: "",
        isDev: false,
    },
    mutations: {},
    actions: {},
    modules: {}
})
