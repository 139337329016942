<template>

    <div class="uk-grid-small" uk-grid>
        <div class="uk-flex uk-flex-middle" v-if="icon">
            <Icon :icon="icon"/>
        </div>

        <div v-if="label && !isLabelHidden" class="uk-flex uk-flex-middle uk-width-expand">
            <label
                :class="(isDisabled ? (withCursorNotAllowed ? 'cursor-notallowed' : '') : 'cursor-pointer ') + 'label-text' +(isSmall ? ' small':'')"
                :for="getId()">{{
                    label
                }}</label>
            <slot/>
        </div>

        <div class="uk-flex uk-flex-middle">
            <label :disabled="isDisabled" :for="getId()" :uk-tooltip="tooltip ? 'title:'+tooltip : ''"
                   :class="'uk-switch' + (isDisabled ? ' disabled' : '')">
                <input :id="getId()" :checked="value" :disabled="isDisabled" type="checkbox"
                       v-on:input="updateValue()">
                <div
                    :class="'uk-switch-slider ' + (isDisabled ? (withCursorNotAllowed ? 'cursor-notallowed' : 'cursor-initial') : '')"/>
            </label>

        </div>
    </div>


</template>

<script>



import Icon from "@/components/Icon.vue";

export default {
    name: 'SwitchButton',
    components: {Icon},
    props: {
        id: String,
        label: String,
        value: Boolean,
        isLabelHidden: Boolean,
        isDisabled: Boolean,
        tooltip: String,
        icon: String,
        isSmall: Boolean,
        withCursorNotAllowed: Boolean,

        itemKey: Number,
    },
    methods: {
        getId() {
            return 'switcher-' + this.label + '-' + this.id;
        },
        updateValue() {
            if (this.isDisabled) {
                return;
            }

            this.$emit('input', !this.value, this.itemKey)
        },
    }
}

</script>


<style lang="scss" scoped>

.label-text {
    font-weight: 400;
    font-size: 16px;

    &.small {
        font-size: $site-font-size-small;
    }
}

.disabled {
    cursor: not-allowed;
}

.uk-switch {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
}

/* Hide default HTML checkbox */
.uk-switch input {
    display: none;
}

/* Slider */
.uk-switch-slider {
    background-color: rgba(255, 255, 255, 0.22);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 500px;
    bottom: 0;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: .2s;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.07);
}

/* Switch pointer */
.uk-switch-slider:before {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    transition-property: transform, box-shadow;
    transition-duration: .2s;
}

/* Slider active color */
input:checked + .uk-switch-slider {
    background-color: $info-color !important;
}

/* Pointer active animation */
input:checked + .uk-switch-slider:before {
    transform: translateX(26px);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
    background-color: #f0506e;
}

input:checked + .uk-switch-slider.uk-switch-on-off {
    background-color: #32d296 !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
    transform: scale(1.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

.uk-switch-slider.uk-switch-small:before {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

input:checked + .uk-switch-slider.uk-switch-big:before {
    transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
    background-color: rgba(255, 255, 255, 0.22);
}

</style>