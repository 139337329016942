<template>
    <div class="telephone-information">

        <a v-if="isTelAvailable()"
           :href="'tel:'+getConfig['tel'+$i18n.locale.toUpperCase()]">
            <Button :text="$t('callNow')" is-large is-min-width mode="primary"/>
        </a>
        <span v-else>
            <Button :text="$t('callNow')"
                    :tooltip="$t(isTomorrowWeekend() ? 'mondayMorningAvailable' : 'tomorrowMorningAvailable')"
                    is-disabled is-large is-min-width mode="primary"/>
        </span>


        <div class="uk-margin-top"/>

        <span v-if="isTelAvailable()" class="available-text">
            <div class="uk-grid-collapse uk-flex uk-flex-center" uk-grid>
                <div class="uk-flex uk-flex-center uk-flex-middle">
                    <span class="dot online"></span> {{ $t('currentlyAvailable') }}
                </div>
            </div>
        </span>
        <span v-else>
             <div class="uk-grid-collapse uk-flex uk-flex-center" uk-grid>
                <div class="uk-flex uk-flex-center uk-flex-middle">
                    <span class="dot offline"></span>
                    <span v-if="isTomorrowWeekend()">{{ $t('mondayMorningAvailable') }}</span>
                    <span v-else>{{ $t('tomorrowMorningAvailable') }}</span>
                </div>
            </div>
        </span>

    </div>
</template>


<script>

import Button from "@/components/Button";

export default {
    name: 'TelephoneInformation',
    components: {Button},
    methods: {
        isTelAvailable() {
            let currentDay = new Date().toLocaleString("de-DE", {timeZone: "Europe/Berlin", weekday: 'short',});
            if (["Sa", "So"].includes(currentDay)) {
                return false;
            }

            let currentHour = new Date().toLocaleString("de-DE", {timeZone: "Europe/Berlin", hour: '2-digit',});
            let currentHourParsed = parseInt(currentHour.split(" ")[0]);

            if (currentHourParsed < 8) {
                return false;
            }

            if (currentHourParsed >= 18) {
                return false;
            }

            return true;
        },
        isTomorrowWeekend() {
            let currentDate = new Date();
            let tomorrowDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
            let tomorrowDay = tomorrowDate.toLocaleString("de-DE", {
                timeZone: "Europe/Berlin",
                weekday: 'short',
            });

            if (["Sa", "So"].includes(tomorrowDay)) {
                return true;
            }

            return false;
        }
    },
}

</script>


<style lang="scss" scoped>

.dot {
    margin-right: 5px;
    height: 9px;
    width: 9px;
    border-radius: 50%;

    &.online {
        background: $success-color;
    }

    &.offline {
        background: $danger-color;
    }
}

.available-text {
    color:  $success-color;
}

</style>
