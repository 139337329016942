<template>
    <div class="admin site-width single">

        <Seo :title="$t('admin')" canonical="/admin"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t('admin')" :message="$t('management')"/>
        <div class="headline-message-distance"/>

        <div class="admin-struct">
            <div v-if="!authorized">
                <div class="uk-grid-small uk-grid-match uk-flex uk-flex-middle uk-flex-center " uk-grid>
                    <div class="uk-width-medium">
                        <input v-model="password" :placeholder="$t('password')" class="uk-input large-input"
                               name="email"
                               type="password"
                               v-on:keyup.enter="login()">
                    </div>
                    <div class="uk-width-auto">

                        <span v-if="password.length>0"
                              v-on:click="login()">
                            <Button :text="$t('login')" is-large is-min-width mode="primary"/>
                        </span>
                        <Button v-else :text="$t('login')" :uk-tooltip="'title:'+$t('pleaseEnterYourPassword')" is-large
                                is-min-width mode="disabled"/>

                    </div>
                </div>
                <div v-if="loginStatus=== 401" class="uk-text-center">
                    <br/>
                    {{ $t('invalidPassword') }}
                </div>

            </div>
            <div v-else>


                <ul uk-tab="animation: uk-animation-fade">
                    <li class="uk-active"><a href="#">{{ $t('news') }}</a></li>
                    <li><a href="#">{{ $t('references') }}</a></li>
                    <li><a href="#">{{ $t('customers') }}</a></li>
                </ul>

                <div class="uk-margin-top"/>
                <ul class="uk-switcher tab-content">

                    <li class="active">
                        <button class="uk-button uk-button-primary button-menu" uk-toggle="target: #modal-news"
                                v-on:click="createNewsItem()">{{ $t('newNews') }}
                        </button>

                        <table class="uk-table uk-table-hover uk-table-divider">
                            <thead>
                            <tr>
                                <th v-for="newsColumn in newsColumns" :key="newsColumn.id">
                                    {{ $t(newsColumn.name) }}
                                </th>
                                <th>
                                    {{ $t('mediaType') }}
                                </th>
                                <th v-for="siteKey in allowedSiteKeys" :key="siteKey">
                                    {{ capitalize(siteKey) }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="newsItem in newsItems" :key="newsItem.id"
                                class="cursor-pointer" uk-toggle="target: #modal-news"
                                v-on:click="selectNews(newsItem)">
                                <td v-for="newsColumn in newsColumns" :key="newsItem.id+newsColumn.name">
                            <span v-if="newsColumn.date">
                                {{ convertDate(newsItem[newsColumn.name]) }}
                            </span>
                                    <span v-else>
                                {{ newsItem[newsColumn.name] }}
                            </span>
                                </td>
                                <td>
                            <span v-if="isVideo(newsItem.media)">
                                <span :uk-tooltip="'title:'+$t('video')"
                                      uk-icon="icon: video-camera; ratio: 1.2"></span>
                            </span>
                                    <span v-else>
                                <span :uk-tooltip="'title:'+$t('imagefilm')" uk-icon="icon: image; ratio: 1.2"></span>
                            </span>
                                </td>
                                <td v-for="siteKey in allowedSiteKeys" :key="siteKey">
                                    <span
                                        :uk-icon="'icon: '+(newsItem.site_keys.includes(siteKey) ? 'check' : 'close')+'; ratio: 1.2'"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </li>


                    <li>
                        <button class="uk-button uk-button-primary button-menu" uk-toggle="target: #modal-reference"
                                v-on:click="createReferenceItem()">{{ $t('newReference') }}
                        </button>

                        <div class="uk-margin-medium"/>
                        <div id="sortable-references" uk-sortable="handle: .uk-sortable-handle">
                            <div v-for="item in referenceItems" :id="item.id" :key="item.id">
                                <div
                                    class="uk-card uk-card-secondary uk-card-body uk-card-small cursor-pointer uk-margin"
                                    uk-toggle="target: #modal-reference"
                                    v-on:click="selectReference(item)"
                                >
                                    <span class="uk-sortable-handle uk-margin-small-right uk-text-center"
                                          uk-icon="icon: table"/>



                                    <img v-if="false && item.logo" :alt="item.logo"
                                         :src="getBaseUrl+'/api/storage/app/'+item.logo"
                                         class="customer-logo"/>
                                    <span v-else>{{ item.name }}</span>
                                    <span class="-left"> ({{ $t(item.category) }})</span>

                                    <span v-if="item.favorite && item.favorite.length>0" :uk-tooltip="'title:'+$t('selection')"
                                          class="uk-margin-small-left" uk-icon="icon: star; ratio: 1.2"/>

                                    <span v-for="siteKey in allowedSiteKeys" :key="siteKey">
                                        <span class="uk-margin-small-left"/>
                                        {{ capitalize(siteKey) }}
                                        <span
                                            :uk-icon="'icon: '+(item.site_keys.includes(siteKey) ? 'check' : 'close')+'; ratio: 1.2'"/>
                                    </span>

                                    <div v-if="false && item.preview_video"
                                         class="uk-cover-container uk-height-medium uk-margin-small-top">
                                        <video :id="'admin-reference'+item.id"
                                               :alt="item.preview_video"
                                               :src="getBaseUrl+'/api/storage/app/'+item.preview_video"
                                               muted
                                               oncontextmenu="return false;" playsinline
                                               uk-cover="autoplay: inview"></video>
                                        <VideoLoop :id="'admin-reference'+item.id"/>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </li>


                    <li>
                        <button class="uk-button uk-button-primary button-menu" uk-toggle="target: #modal-customer"
                                v-on:click="createCustomerItem()">{{ $t('newCustomer') }}
                        </button>


                        <div class="uk-margin-medium"/>
                        <div id="sortable-customers" uk-sortable="handle: .uk-sortable-handle">
                            <div v-for="item in customerItems" :id="item.id" :key="item.id">
                                <div
                                    class="uk-card uk-card-secondary uk-card-body uk-card-small cursor-pointer uk-margin"
                                    uk-toggle="target: #modal-customer"
                                    v-on:click="selectCustomer(item)"
                                >
                                    <span class="uk-sortable-handle uk-margin-small-right uk-text-center"
                                          uk-icon="icon: table">
                                    </span>

                                    <img :alt="item.logo" :src="getBaseUrl+'/api/storage/app/'+item.logo"
                                         class="customer-logo"/>

                                    <span v-for="siteKey in allowedSiteKeys" :key="siteKey">
                                        <span class="uk-margin-small-left"/>
                                        {{ capitalize(siteKey) }}
                                        <span
                                            :uk-icon="'icon: '+(item.site_keys.includes(siteKey) ? 'check' : 'close')+'; ratio: 1.2'"/>
                                    </span>

                                </div>
                            </div>
                        </div>

                    </li>
                </ul>


            </div>
        </div>

        <div class="last-section-distance"/>

        <div id="modal-news" uk-modal="esc-close: false; bg-close: false;">
            <div class="uk-modal-dialog">
                <button class="uk-visible@m uk-modal-close-outside" type="button" uk-close></button>
                <button class="uk-hidden@m uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header uk-light">
                    <h2 class="uk-modal-title uk-margin-bottom uk-margin-remove-top">
                        {{ $t(selectedNews && selectedNews.id > 0 ? 'editNews' : 'newNews') }}
                    </h2>
                </div>
                <div v-if="selectedNews" class="uk-modal-body uk-light">

                    <div uk-grid>
                        <div v-for="siteKey in allowedSiteKeys" :key="siteKey">

                            <span v-on:click="toggleSiteKeyNews(siteKey)">
                                <label :for="'n-'+siteKey" class="cursor-pointer">
                                    <input :name="'n-'+siteKey"
                                           :checked="selectedNews.site_keys.includes(siteKey)"
                                           class="uk-checkbox uk-margin-small-right"
                                           type="checkbox"> {{ capitalize(siteKey) }}
                                </label>
                            </span>

                        </div>
                    </div>


                    <div class="uk-margin"/>
                    <div class="input-headline">{{ $t('released_at') }}</div>
                    <v-date-picker v-model='selectedNewsDate'
                                   is-dark
                    ></v-date-picker>


                    <div class="uk-margin"/>
                    <div class="input-headline">{{ $t('headlineDE') }}</div>
                    <input v-model="selectedNews.headlineDE" class="uk-input" type="text">

                    <div class="uk-margin-top"/>
                    <div class="input-headline">{{ $t('messageDE') }}</div>
                    <textarea v-model="selectedNews.messageDE" class="uk-textarea" rows="5"></textarea>

                    <div class="uk-margin"/>

                    <div class="input-headline">{{ $t('headlineEN') }}</div>
                    <input v-model="selectedNews.headlineEN" class="uk-input" type="text">
                    <div class="uk-margin-top"/>
                    <div class="input-headline">{{ $t('messageEN') }}</div>
                    <textarea v-model="selectedNews.messageEN" class="uk-textarea" rows="5"></textarea>

                    <div class="uk-margin"/>

                    <div class="input-headline">{{ $t('media') }}</div>

                    <div class="uk-margin-bottom" uk-form-custom>
                        <input id="fileNews" ref="fileNews" accept="image/png, image/jpeg, video/mp4" type="file"
                               v-on:click="$refs.fileNews.value=null"
                               v-on:change="handleFilesUploadNews()"/>
                        <button class="uk-button uk-button-default cursor-pointer" tabindex="-1" type="button">
                            {{ $t('select') }}
                        </button>
                    </div>

                    <div class="uk-height-medium">
                        <span v-if="fileNews">
                            <div v-if="isVideo(fileNews.name)" class="uk-cover-container uk-height-1-1">
                                <video :id="'admin-news'+item.id"
                                       :src="getLocalFileUrl(fileNews)"
                                       muted
                                       oncontextmenu="return false;" playsinline uk-cover="autoplay: inview"></video>
                                <VideoLoop :id="'admin-news'+item.id"/>
                            </div>
                            <div v-else-if="fileNews.name"
                                 class="uk-cover-container uk-height-1-1">
                                <img :alt="fileNews.name" :src="getLocalFileUrl(fileNews)"
                                     uk-cover>
                            </div>
                               <div v-else>
                                <div class="js-upload uk-placeholder uk-height-medium"/>
                            </div>
                        </span>
                        <span v-else>
                            <div v-if="isVideo(selectedNews.media)" class="uk-cover-container uk-height-1-1">
                                <video :id="'admin-news'+item.id"
                                       :src="getBaseUrl+'/api/storage/app/'+selectedNews.media"
                                       muted
                                       oncontextmenu="return false;" playsinline uk-cover="autoplay: inview"></video>
                                <VideoLoop :id="'admin-news'+item.id"/>
                            </div>
                            <div v-else-if="selectedNews.media"
                                 class="uk-cover-container uk-height-1-1">
                                <img :alt="selectedNews.media" :src="getBaseUrl+'/api/storage/app/'+selectedNews.media"
                                     uk-cover>
                            </div>
                            <div v-else>
                                <div class="js-upload uk-placeholder uk-height-medium"/>
                            </div>
                        </span>
                    </div>

                    <div class="uk-margin"/>

                    <div class="input-headline">{{ $t('link') }}</div>
                    <input v-model="selectedNews.link" class="uk-input" type="text">

                </div>
                <div class="uk-modal-footer">
                    <div class="uk-grid-small" uk-grid>
                        <div v-if="selectedNews && selectedNews.id > 0" class="uk-width-auto">
                            <div class="uk-inline">
                                <button class="uk-button uk-button-danger" type="button">{{ $t('delete') }}</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav">
                                        <li class="uk-nav-header">{{ $t('reallyDelete') }}</li>
                                        <li>
                                            <button class="uk-button uk-button-danger"
                                                    type="button" v-on:click="deleteNews(selectedNews)">{{ $t('yes') }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="uk-text-right uk-width-expand uk-light">
                            <button class="uk-button uk-button-default uk-modal-close" type="button">{{ $t('cancel') }}
                            </button>
                        </div>
                        <div class="uk-text-right uk-width-auto">
                            <button class="uk-button uk-button-primary"
                                    type="button" v-on:click="createOrUpdateNews(selectedNews)">
                                <span v-if="selectedNews && selectedNews.id > 0">
                                     {{ $t('save') }}
                                </span>
                                <span v-else>
                                     {{ $t('create') }}
                                </span>
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>


        <div id="modal-customer" uk-modal>
            <div class="uk-modal-dialog ">
                <button class="uk-visible@m uk-modal-close-outside" type="button" uk-close></button>
                <button class="uk-hidden@m uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header uk-light">
                    <h2 class="uk-modal-title uk-margin-bottom uk-margin-remove-top">
                        {{ $t(selectedCustomer && selectedCustomer.id > 0 ? 'editCustomer' : 'newCustomer') }}
                    </h2>
                </div>
                <div v-if="selectedCustomer" class="uk-modal-body uk-light">

                    <div uk-grid>
                        <div v-for="siteKey in allowedSiteKeys" :key="siteKey">

                            <span v-on:click="toggleSiteKeyCustomer(siteKey)">
                                <label :for="'c-'+siteKey" class="cursor-pointer">
                                    <input
                                        :name="'c-'+siteKey"
                                        :checked="selectedCustomer.site_keys.includes(siteKey)"
                                        class="uk-checkbox uk-margin-small-right"
                                        type="checkbox"> {{ capitalize(siteKey) }}
                                </label>
                            </span>

                        </div>
                    </div>

                    <div class="uk-margin"/>
                    <div class="input-headline">{{ $t('logo') }}</div>
                    <div class="uk-margin-bottom" uk-form-custom>
                        <input id="fileCustomer" ref="fileCustomer" accept="image/png, image/jpeg, image/svg+xml"
                               type="file"
                               v-on:click="$refs.fileCustomer.value=null"
                               v-on:change="handleFilesUploadCustomer()"/>
                        <button class="uk-button uk-button-default cursor-pointer" tabindex="-1" type="button">
                            {{ $t('select') }}
                        </button>
                    </div>
                    <div class="uk-heading-small">
                        <span v-if="fileCustomer">
                            <div v-if="fileCustomer.name"
                                 class="uk-cover-container">
                                <img :alt="fileCustomer.name" :src="getLocalFileUrl(fileCustomer)">
                            </div>
                               <div v-else>
                                <div class="js-upload uk-placeholder"/>
                            </div>
                        </span>
                        <span v-else>
                            <div v-if="selectedCustomer.logo"
                                 class="uk-cover-container">
                                <img :alt="selectedCustomer.logo"
                                     :src="getBaseUrl+'/api/storage/app/'+selectedCustomer.logo">
                            </div>
                            <div v-else>
                                <div class="js-upload uk-placeholder"/>
                            </div>
                        </span>
                    </div>

                </div>
                <div class="uk-modal-footer">
                    <div class="uk-grid-small" uk-grid>
                        <div v-if="selectedCustomer && selectedCustomer.id > 0" class="uk-width-auto">
                            <div class="uk-inline">
                                <button class="uk-button uk-button-danger" type="button">{{ $t('delete') }}</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav">
                                        <li class="uk-nav-header">{{ $t('reallyDelete') }}</li>
                                        <li>
                                            <button class="uk-button uk-button-danger"
                                                    type="button" v-on:click="deleteCustomer(selectedCustomer)">
                                                {{ $t('yes') }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="uk-text-right uk-width-expand uk-light">
                            <button class="uk-button uk-button-default uk-modal-close" type="button">{{ $t('cancel') }}
                            </button>
                        </div>
                        <div class="uk-text-right uk-width-auto">
                            <button class="uk-button uk-button-primary"
                                    type="button" v-on:click="createOrUpdateCustomer(selectedCustomer)">
                                <span v-if="selectedCustomer && selectedCustomer.id > 0">
                                     {{ $t('save') }}
                                </span>
                                <span v-else>
                                     {{ $t('create') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id="modal-reference" uk-modal>
            <div class="uk-modal-dialog ">
                <button class="uk-visible@m uk-modal-close-outside" type="button" uk-close></button>
                <button class="uk-hidden@m uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header uk-light">
                    <h2 class="uk-modal-title uk-margin-bottom uk-margin-remove-top">
                        {{ $t(selectedReference && selectedReference.id > 0 ? 'editReference' : 'newReference') }}
                    </h2>
                </div>
                <div v-if="selectedReference" class="uk-modal-body uk-light">

                    <div uk-grid>
                        <div v-for="siteKey in allowedSiteKeys" :key="siteKey">

                            <span v-on:click="toggleSiteKeyReference(siteKey)">
                                <label :for="'r-'+siteKey" class="cursor-pointer">
                                    <input :name="'r-'+siteKey" :checked="selectedReference.site_keys.includes(siteKey)"
                                           class="uk-checkbox uk-margin-small-right"
                                           type="checkbox"> {{ capitalize(siteKey) }}
                                </label>
                            </span>

                        </div>
                    </div>

                    <div class="uk-margin"/>
                    <div class="input-headline">{{ $t('category') }}*</div>
                    <select v-model="selectedReference.category" class="uk-select">
                        <option value="commercial">Werbung</option>
                        <option value="image">Image</option>
                        <option value="fiction">Fiktion</option>
                        <option value="recruiting">Recruiting</option>
                        <option value="behindTheScenes">Behind The Scenes</option>
                    </select>

                    <div class="uk-margin-top"/>
                    <div class="input-headline">{{ $t('selection') }}*</div>
                    <div class="input-headline small">Auswahl Videos werden auf der Startseite angezeigt und bei den
                        Referenzen hervorgehoben.
                    </div>

                    <div uk-grid>
                        <div v-for="siteKey in allowedSiteKeys" :key="siteKey">
                            <span v-on:click="toggleReferenceFavorite(siteKey)">
                                <label :for="'rf-'+siteKey" class="cursor-pointer">
                                    <input :name="'rf-'+siteKey" :checked="selectedReference.favorite.includes(siteKey)"
                                           class="uk-checkbox uk-margin-small-right"
                                           type="checkbox"> {{ capitalize(siteKey) }}
                                </label>
                            </span>
                        </div>
                    </div>

                    <div class="uk-margin-top"/>
                    <div class="input-headline">{{ $t('name') }}*</div>
                    <input v-model="selectedReference.name" class="uk-input" type="text">

                    <div class="uk-margin-top"/>
                    <div class="input-headline">{{ $t('logo') }} (optional)</div>
                    <div class="uk-margin-bottom" uk-form-custom>
                        <input id="fileReferenceLogo" ref="fileReferenceLogo"
                               accept="image/png, image/jpeg, image/svg+xml"
                               type="file"
                               v-on:click="$refs.fileReferenceLogo.value=null"
                               v-on:change="handleFilesUploadReferenceLogo()"/>
                        <button class="uk-button uk-button-default cursor-pointer" tabindex="-1" type="button">
                            {{ $t('select') }}
                        </button>
                    </div>
                    <div>
                        <span v-if="fileReferenceLogo">
                            <div v-if="fileReferenceLogo.name"
                                 class="">
                                <img :alt="fileReferenceLogo.name" :src="getLocalFileUrl(fileReferenceLogo)"
                                     class="customer-logo">

                                 <div class="uk-margin-top">
                                     <span v-on:click="fileReferenceLogo= null">
                                           <Button mode="danger">Logo Löschen</Button>
                                     </span>

                                </div>
                            </div>
                               <div v-else>
                                <div class="js-upload uk-placeholder"/>
                            </div>
                        </span>
                        <span v-else>
                            <div v-if="selectedReference.logo"
                                 class="">
                                <img :alt="selectedReference.logo"
                                     :src="getBaseUrl+'/api/storage/app/'+selectedReference.logo"
                                     class="customer-logo">

                                 <div class="uk-margin-top">
                                     <span
                                         v-on:click="deleteLogo= true; fileReferenceLogo = null; selectedReference.logo= null">
                                         <Button mode="danger">Logo Löschen</Button>
                                     </span>
                                </div>
                            </div>
                            <div v-else>
                                <div class="js-upload uk-placeholder"/>
                            </div>
                        </span>
                    </div>


                    <div class="uk-margin-top"/>
                    <div class="input-headline">{{ $t('caption') }}</div>
                    <input v-model="selectedReference.caption" class="uk-input" type="text">


                    <div class="uk-margin"/>
                    <div class="input-headline">{{ $t('preview_video') }}*</div>
                    <div class="uk-margin-bottom" uk-form-custom>
                        <input id="fileReferenceVideo" ref="fileReferenceVideo" accept="video/mp4"
                               type="file"
                               v-on:click="$refs.fileReferenceVideo.value=null"
                               v-on:change="handleFilesUploadReferenceVideo()"/>
                        <button class="uk-button uk-button-default cursor-pointer" tabindex="-1" type="button">
                            {{ $t('select') }}
                        </button>
                    </div>
                    <div class="uk-height-medium">
                        <span v-if="fileReferenceVideo">
                            <div v-if="fileReferenceVideo.name" class="uk-cover-container uk-height-1-1">
                                <video :id="'admin-ref'+selectedReference.id"
                                       :src="getLocalFileUrl(fileReferenceVideo)"
                                       muted
                                       oncontextmenu="return false;" playsinline uk-cover="autoplay: inview"></video>
                                <VideoLoop :id="'admin-ref'+selectedReference.id"/>
                            </div>
                               <div v-else>
                                <div class="js-upload uk-placeholder uk-height-medium"/>
                            </div>
                        </span>
                        <span v-else>
                            <div v-if="selectedReference.preview_video" class="uk-cover-container uk-height-1-1">
                                <video :id="'admin-ref'+selectedReference.id"
                                       :alt="selectedReference.preview_video"
                                       :src="getBaseUrl+'/api/storage/app/'+selectedReference.preview_video"
                                       muted
                                       oncontextmenu="return false;" playsinline uk-cover="autoplay: inview"></video>
                                <VideoLoop :id="'admin-ref'+selectedReference.id"/>
                            </div>
                               <div v-else>
                                <div class="js-upload uk-placeholder uk-height-medium"/>
                            </div>
                        </span>
                    </div>

                    <ul class="uk-child-width-expand" uk-tab="animation: uk-animation-fade">
                        <li :class="selectedReference.one_video ? 'uk-active' : ''"
                            v-on:click="selectedReference.single_video= true">
                            <a href="#">Ein Video*</a>
                        </li>

                        <li :class="selectedReference.one_video ? '' : 'uk-active'"
                            v-on:click="selectedReference.single_video= false">
                            <a href="#">Mehrere Videos/Fotos*</a>
                        </li>
                    </ul>
                    <ul class="uk-switcher tab-content">
                        <li :class="selectedReference.one_video ? 'uk-active' : ''">
                            <div class="input-headline">{{ $t('video_link') }}*</div>
                            <input v-model="selectedReference.video_link" class="uk-input" type="text">
                        </li>

                        <li :class="selectedReference.one_video ? '' : 'uk-active'">
                            <div class="input-headline">Beschreibung DE (optional)</div>
                            <input v-model="selectedReference.descriptionDE" class="uk-input" type="text">

                            <div class="uk-margin-top"/>
                            <div class="input-headline">Beschreibung EN (optional)</div>
                            <input v-model="selectedReference.descriptionEN" class="uk-input" type="text">

                            <div class="uk-margin-medium"/>
                            <div class="uk-child-width-expand" uk-grid>
                                <div>
                                    <button class="uk-button uk-button-default cursor-pointer uk-width-1-1"
                                            type="button"
                                            v-on:click="addVideo()">
                                        +Video
                                    </button>
                                </div>
                                <div>
                                    <button class="uk-button uk-button-default cursor-pointer uk-width-1-1"
                                            type="button"
                                            v-on:click="addImage()">
                                        +Foto
                                    </button>
                                </div>
                            </div>
                            <div class="uk-margin-medium-top"/>


                            <div v-for="(item, key) in selectedReference.videos" :key="'v'+key" class="uk-grid-match"
                                 uk-grid>
                                <div class="uk-width-medium">
                                    <div v-if="item.preview"
                                         class="uk-cover-container uk-height-1-1">
                                        <video :id="'admin-sref'+item.id"
                                               :src="getBaseUrl+'/api/storage/app/'+item.preview"
                                               muted
                                               oncontextmenu="return false;" playsinline
                                               uk-cover="autoplay: inview"></video>
                                        <VideoLoop :id="'admin-sref'+item.id"/>
                                    </div>
                                    <div v-else-if="item.file"
                                         class="uk-cover-container uk-height-1-1">
                                        <video :id="'admin-sref'+item.id"
                                               :src="getLocalFileUrl(item.file)"
                                               muted
                                               oncontextmenu="return false;" playsinline
                                               uk-cover="autoplay: inview"></video>
                                        <VideoLoop :id="'admin-sref'+item.id"/>
                                    </div>
                                    <div v-else>
                                        <div class="js-upload uk-placeholder uk-height-1-1"/>
                                    </div>
                                </div>
                                <div class="uk-width-expand">
                                    <div class="input-headline">Vorschauvideo*</div>
                                    <div class="uk-margin-bottom" uk-form-custom>
                                        <input :id="'fileReferenceVideo'+key" :ref="'fileReferenceVideo'+key"
                                               accept="video/mp4"
                                               type="file"
                                               v-on:click="$refs['fileReferenceVideo'+key].value=null"
                                               v-on:change="handleFilesUploadVideo(key)"/>
                                        <button class="uk-button uk-button-default cursor-pointer uk-width-1-1"
                                                tabindex="-1"
                                                type="button">
                                            {{ $t('select') }}
                                        </button>
                                    </div>

                                    <div class="input-headline">{{ $t('name') }}*</div>
                                    <input v-model="item.name" class="uk-input" type="text">

                                    <div class="uk-margin-top"/>
                                    <div class="input-headline">{{ $t('video_link') }}*</div>
                                    <input v-model="item.link" class="uk-input" type="text">

                                    <div class="uk-margin-top"/>
                                    <button class="uk-button uk-button-danger cursor-pointer"
                                            type="button" v-on:click="removeVideo(key)">
                                        {{ $t('delete') }}
                                    </button>
                                </div>
                            </div>


                            <div v-for="(item, key) in selectedReference.pictures" :key="'p'+key" class="uk-grid-match"
                                 uk-grid>
                                <div class="uk-width-medium">
                                    <div v-if="item.picture"
                                         class="uk-cover-container uk-height-1-1">
                                        <img :src="getBaseUrl+'/api/storage/app/'+item.picture"/>
                                    </div>
                                    <div v-else-if="item.file"
                                         class="uk-cover-container uk-height-1-1">
                                        <img :src="getLocalFileUrl(item.file)"/>
                                    </div>
                                    <div v-else>
                                        <div class="js-upload uk-placeholder uk-height-1-1"/>
                                    </div>
                                </div>
                                <div class="uk-width-expand">
                                    <div class="input-headline">Vorschaubild*</div>
                                    <div class="uk-margin-bottom" uk-form-custom>
                                        <input :id="'fileReferencePictures'+key" :ref="'fileReferencePictures'+key"
                                               accept="image/png, image/jpeg"
                                               type="file"
                                               v-on:click="$refs['fileReferencePictures'+key].value=null"
                                               v-on:change="handleFilesUploadPictures(key)"/>
                                        <button class="uk-button uk-button-default cursor-pointer uk-width-1-1"
                                                type="button">
                                            {{ $t('select') }}
                                        </button>
                                    </div>

                                    <div class="uk-margin-top"/>
                                    <button class="uk-button uk-button-danger cursor-pointer"
                                            type="button" v-on:click="removePicture(key)">
                                        {{ $t('delete') }}
                                    </button>
                                </div>
                            </div>


                        </li>
                    </ul>
                </div>
                <div class="uk-modal-footer">
                    <div class="uk-grid-small" uk-grid>
                        <div v-if="selectedReference && selectedReference.id > 0" class="uk-width-auto">
                            <div class="uk-inline">
                                <button class="uk-button uk-button-danger" type="button">{{ $t('delete') }}</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav">
                                        <li class="uk-nav-header">{{ $t('reallyDelete') }}</li>
                                        <li>
                                            <button class="uk-button uk-button-danger"
                                                    type="button" v-on:click="deleteReference(selectedReference)">
                                                {{ $t('yes') }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="uk-text-right uk-width-expand uk-light">
                            <button class="uk-button uk-button-default uk-modal-close" type="button">{{ $t('cancel') }}
                            </button>
                        </div>
                        <div class="uk-text-right uk-width-auto">
                            <button class="uk-button uk-button-primary"
                                    type="button" v-on:click="createOrUpdateReference(selectedReference)">
                                <span v-if="selectedReference && selectedReference.id > 0">
                                     {{ $t('save') }}
                                </span>
                                <span v-else>
                                     {{ $t('create') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<script>

import Seo from "../components/Seo";
import SiteHeadline from "../components/SiteHeadline";
import axios from "axios";
import UIkit from "uikit";
import Button from "@/components/Button";
import VideoLoop from "@/components/VideoLoop";
import Vue from "vue";

export default {
    name: 'Admin',
    components: {
        VideoLoop,
        Button,
        SiteHeadline,
        Seo,
    },
    data() {
        return {
            password: "",
            authorized: false,
            loginStatus: null,

            newsColumns: [
                {
                    name: "released_at",
                    date: true,
                },
                {
                    name: "headlineDE",
                },
                {
                    name: "headlineEN",
                },
            ],

            newsItems: [],
            selectedNews: null,
            selectedNewsDate: null,
            fileNews: null,

            customerItems: [],
            selectedCustomer: null,
            fileCustomer: null,

            referenceItems: [],
            selectedReference: null,
            fileReferenceLogo: null,
            fileReferenceVideo: null,
            deleteLogo: false,

            allowedSiteKeys: [],
        };
    },
    created() {
        axios.defaults.timeout = this.apiTimeout;

        let password = this.$cookie.get("password");
        if (password) {
            this.password = password;
            this.login();
        }
    },
    mounted() {
        let self = this;

        document.addEventListener("moved", function (e) {
            if (e.target.id === 'sortable-references') {
                let sortable = UIkit.sortable("#sortable-references");
                let sorts = [];
                sortable.items.forEach(function (item, index) {
                    sorts.push({
                        id: item.id,
                        sort: index,
                    })
                });
                self.setSort('references', sorts);

            } else if (e.target.id === 'sortable-customers') {
                let sortable = UIkit.sortable("#sortable-customers");
                let sorts = [];
                sortable.items.forEach(function (item, index) {
                    sorts.push({
                        id: item.id,
                        sort: index,
                    })
                });
                self.setSort('customers', sorts);
            }

        });
    },
    methods: {
        addVideo() {
            this.selectedReference.videos.push({
                name: "",
                preview: "",
                link: "",
                file: null,
            });
        },
        addImage() {
            this.selectedReference.pictures.push({
                name: "",
                picture: "",
                file: null,
            });
        },
        getLocalFileUrl(file) {
            return URL.createObjectURL(file);
        },
        isVideo(type) {
            if (type.includes(".mp4")) {
                return true;
            }

            return false;
        },

        handleFilesUploadNews() {
            this.fileNews = this.$refs.fileNews.files[0];
        },
        handleFilesUploadReferenceLogo() {
            this.fileReferenceLogo = this.$refs.fileReferenceLogo.files[0];
        },
        handleFilesUploadReferenceVideo() {
            this.fileReferenceVideo = this.$refs.fileReferenceVideo.files[0];
        },
        handleFilesUploadCustomer() {
            this.fileCustomer = this.$refs.fileCustomer.files[0];
        },
        handleFilesUploadVideo(key) {
            this.selectedReference.videos[key].file = this.$refs['fileReferenceVideo' + key][0].files[0];
        },
        handleFilesUploadPictures(key) {
            this.selectedReference.pictures[key].file = this.$refs['fileReferencePictures' + key][0].files[0];
        },
        removeVideo(key) {
            this.selectedReference.videos.splice(key, 1);
        },
        removePicture(key) {
            this.selectedReference.pictures.splice(key, 1);
        },
        toggleSiteKeyNews(siteKey) {
            let siteKeys = this.selectedNews.site_keys;

            if (!siteKeys.includes(siteKey)) {
                siteKeys.push(siteKey);

            } else {
                for (let i = 0, j = siteKeys.length; i < j; i++) {
                    if (siteKeys[i] === siteKey) {
                        siteKeys.splice(i, 1);
                        break;
                    }
                }

            }

            Vue.set(this.selectedNews, "site_keys", siteKeys);
        },
        toggleSiteKeyReference(siteKey) {
            let siteKeys = this.selectedReference.site_keys;

            if (!siteKeys.includes(siteKey)) {
                siteKeys.push(siteKey);

            } else {
                for (let i = 0, j = siteKeys.length; i < j; i++) {
                    if (siteKeys[i] === siteKey) {
                        siteKeys.splice(i, 1);
                        break;
                    }
                }

            }

            Vue.set(this.selectedReference, "site_keys", siteKeys);
        },
        toggleReferenceFavorite(siteKey) {
            let siteKeys = this.selectedReference.favorite;

            if (!siteKeys.includes(siteKey)) {
                siteKeys.push(siteKey);

            } else {
                for (let i = 0, j = siteKeys.length; i < j; i++) {
                    if (siteKeys[i] === siteKey) {
                        siteKeys.splice(i, 1);
                        break;
                    }
                }

            }

            Vue.set(this.selectedReference, "favorite", siteKeys);
        },
        toggleSiteKeyCustomer(siteKey) {
            let siteKeys = this.selectedCustomer.site_keys;

            if (!siteKeys.includes(siteKey)) {
                siteKeys.push(siteKey);

            } else {
                for (let i = 0, j = siteKeys.length; i < j; i++) {
                    if (siteKeys[i] === siteKey) {
                        siteKeys.splice(i, 1);
                        break;
                    }
                }

            }

            Vue.set(this.selectedCustomer, "site_keys", siteKeys);
        },
        selectNews(newsItem) {
            this.fileNews = null;

            this.selectedNews = JSON.parse(JSON.stringify(newsItem));
            this.selectedNewsDate = new Date(this.selectedNews.released_at);

            if (this.selectedNews.site_keys) {
                this.selectedNews.site_keys = JSON.parse(this.selectedNews.site_keys);
            } else {
                this.selectedNews.site_keys = [];
            }
        },
        selectCustomer(newsItem) {
            this.fileCustomer = null;

            this.selectedCustomer = JSON.parse(JSON.stringify(newsItem));

            if (this.selectedCustomer.site_keys) {
                this.selectedCustomer.site_keys = JSON.parse(this.selectedCustomer.site_keys);
            } else {
                this.selectedCustomer.site_keys = [];
            }
        },
        selectReference(newsItem) {
            this.fileReferenceLogo = null;
            this.fileReferenceVideo = null;

            this.selectedReference = JSON.parse(JSON.stringify(newsItem));
            this.selectedReference.one_video = newsItem.video_link !== "";
            this.selectedReference.single_video = this.selectedReference.one_video;

            if (this.selectedReference.videos) {
                this.selectedReference.videos = JSON.parse(this.selectedReference.videos);
            } else {
                this.selectedReference.videos = [];
            }

            if (this.selectedReference.pictures) {
                this.selectedReference.pictures = JSON.parse(this.selectedReference.pictures);
            } else {
                this.selectedReference.pictures = [];
            }

            if (this.selectedReference.site_keys) {
                this.selectedReference.site_keys = JSON.parse(this.selectedReference.site_keys);
            } else {
                this.selectedReference.site_keys = [];
            }

            if (this.selectedReference.favorite) {
                this.selectedReference.favorite = JSON.parse(this.selectedReference.favorite);
            } else {
                this.selectedReference.favorite = [];
            }
        },
        createNewsItem() {
            this.selectedNews = {
                headlineDE: "",
                headlineEN: "",
                id: 0,
                media: "",
                messageDE: "",
                messageEN: "",
                released_at: new Date(),
                link: "",
                site_keys: [],
            };

            this.selectedNewsDate = new Date(this.selectedNews.released_at);
        },
        createReferenceItem() {
            this.selectedReference = {
                id: 0,
                category: "",
                name: "",
                descriptionDE: "",
                descriptionEN: "",
                logo: "",
                preview_video: "",
                favorite: [],
                site_keys: [],
                caption: "",

                single_video: true,
                one_video: true,
                video_link: "",
                videos: [],
                pictures: []
            };
        },
        createCustomerItem() {
            this.selectedCustomer = {
                id: 0,
                logo: "",
                site_keys: [],
            };
        },

        getNews() {
            let http = axios.create();

            this.loading = true;
            http.get(this.getBaseUrl + '/api/news/all').then(response => {
                this.newsItems = response.data;
                this.getCustomers();

            }).catch(e => {
                this.error = e;
                this.loading = false;

            });
        },
        getCustomers() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/customers/all').then(response => {
                this.customerItems = response.data;
                this.getReferences();

            }).catch(e => {
                this.error = e;
                this.loading = false;

            });
        },
        getReferences() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/references/all').then(response => {
                this.referenceItems = response.data;
                this.error = false;
                this.loading = false;

            }).catch(e => {
                this.error = e;
                this.loading = false;

            });
        },

        createOrUpdateNews(selectedNews) {
            let formData = new FormData();
            let newReleasedAt = new Date(this.selectedNewsDate);
            formData.append("released_at", newReleasedAt.getTime());
            formData.append("headlineDE", selectedNews.headlineDE);
            formData.append("messageDE", selectedNews.messageDE);
            formData.append("headlineEN", selectedNews.headlineEN);
            formData.append("messageEN", selectedNews.messageEN);
            formData.append("link", selectedNews.link);
            formData.append("site_keys", JSON.stringify(selectedNews.site_keys));

            if (this.fileNews !== null) {
                formData.append('media', this.fileNews);
            }

            let http = axios.create();

            http.post(this.getBaseUrl + '/api/news' + (selectedNews.id === 0 ? '' : ('/' + selectedNews.id)), formData).then(() => {
                this.getNews();
                UIkit.modal("#modal-news").hide();

            }).catch(() => {

            });
        },
        deleteNews(selectedNews) {
            let http = axios.create();

            http.delete(this.getBaseUrl + '/api/news/' + selectedNews.id).then(() => {
                this.getNews();
                UIkit.modal("#modal-news").hide();

            }).catch(() => {
            });
        },
        createOrUpdateCustomer(item) {
            let formData = new FormData();
            formData.append('logo', this.fileCustomer);
            formData.append("site_keys", JSON.stringify(item.site_keys));

            let http = axios.create();

            http.post(this.getBaseUrl + '/api/customers' + (item.id === 0 ? '' : ('/' + item.id)), formData).then(() => {
                this.fileCustomer = null;
                this.getCustomers();
                UIkit.modal("#modal-customer").hide();

            }).catch(() => {
            });
        },
        deleteCustomer(selectedNews) {
            let http = axios.create();

            http.delete(this.getBaseUrl + '/api/customers/' + selectedNews.id).then(() => {
                this.getCustomers();
                UIkit.modal("#modal-customer").hide();

            }).catch(() => {
            });
        },
        createOrUpdateReference(item) {
            let formData = new FormData();
            formData.append("category", item.category);
            formData.append("name", item.name);
            formData.append("caption", item.caption);
            formData.append("descriptionDE", item.descriptionDE);
            formData.append("descriptionEN", item.descriptionEN);
            formData.append('preview_video', this.fileReferenceVideo);
            formData.append("favorite",  JSON.stringify(item.favorite));
            formData.append("site_keys", JSON.stringify(item.site_keys));
            formData.append("deleteLogo", this.deleteLogo);

            if (item.single_video) {
                formData.append("video_link", item.video_link);

            } else {
                formData.append("video_link", "");

                for (let i = 0, j = item.videos.length; i < j; i++) {
                    formData.append("video" + i, item.videos[i].file);
                }

                for (let i = 0, j = item.pictures.length; i < j; i++) {
                    formData.append("picture" + i, item.pictures[i].file);
                }

                formData.append("videos", JSON.stringify(item.videos));
                formData.append("pictures", JSON.stringify(item.pictures));

            }

            if (this.fileReferenceLogo !== null) {
                formData.append('logo', this.fileReferenceLogo);
            }

            let http = axios.create();

            http.post(this.getBaseUrl + '/api/references' + (item.id === 0 ? '' : ('/' + item.id)), formData).then(() => {
                this.fileReferenceLogo = null;
                this.fileReferenceVideo = null;
                this.getReferences();
                UIkit.modal("#modal-reference").hide();

            }).catch(() => {

            });
        },
        deleteReference(selectedNews) {
            let http = axios.create();

            http.delete(this.getBaseUrl + '/api/references/' + selectedNews.id).then(() => {
                this.getReferences();
                UIkit.modal("#modal-reference").hide();

            }).catch(() => {

            });
        },
        login() {
            axios.defaults.headers.common["password"] = this.password;

            let http = axios.create();
            http.post(this.getBaseUrl + '/api/login').then(() => {
                this.$cookie.set("password", this.password, 30);
                this.authorized = true;
                this.loginStatus = 200;

                this.getSiteKeys();

            }).catch(e => {
                this.loginStatus = 500;
                if (e && e.response) {
                    this.loginStatus = e.response.status;
                }

            });
        },
        getSiteKeys() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/sites/keys').then(response => {
                this.allowedSiteKeys = response.data;
                this.getNews();

            }).catch(e => {
                this.error = e;
                this.loading = false;

            });
        },
        setSort(list, sorts) {
            let http = axios.create();

            let formData = new FormData();
            formData.append('sorts', JSON.stringify(sorts));

            http.post(this.getBaseUrl + '/api/' + list + '/sort', formData).then(() => {

            }).catch(() => {
            });
        },

    }
}
</script>


<style lang="scss" scoped>

.admin-struct {
    min-height: calc(100vh - 70px - 70px - 177px - 279px);
}

.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
    background: $alternative-color !important;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}

.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
    background: $alternative-color !important;
}

.uk-input {
    color: #ddd !important;
}

.uk-switcher {
    margin-top: 25px;
}

.uk-tab > * > a {
    text-transform: initial !important;
    font-size: 16px;
    font-weight: 400;
    border-color: transparent;
}

.customer-logo {
    max-width: 200px !important;
    max-height: 40px !important;
}

.video-item {
    height: 200px;
    margin-top: 5px;
}

.small {
    font-size: 15px;
}

</style>
