<template>
    <div>
        <SiteHeadline :headline="headline" :message="message" :switch-headline="switchHeadline"
                      class=""/>

        <div uk-grid
             class=" section-headline-distance uk-grid-match uk-text-center uk-flex uk-flex-center uk-child-width-1-5@xl uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1">
            <div v-for="(fact, key) in facts" :key="key" class="uk-text-center">
                <div class="uk-padding-small">
                    <img :alt="key"
                         :src="require('../assets/'+getMode+'/images/'+(key+1)+'.png')"
                         class="customer-logo" uk-img/>

                    <div class="fact-name">{{ fact }}</div>

                </div>
            </div>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";

export default {
    name: 'FactsList',
    components: {SiteHeadline},
    props: {
        headline: String,
        message: String,
        facts: Array,
        switchHeadline: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.customer-list {
    padding: 80px 40px 80px 40px !important;
}

.customer-logo {
    max-height: 90px;
    max-width: 200px;
}

@media (max-width: $breakpoint-small) {
    .customer-logo {
        max-height: 60px;
        max-width: 160px;
    }
}

@media (max-width: 400px) {
    .customer-logo {
        max-height: 40px;
        max-width: 110px;
    }
}

.section-headline-distance {
    margin-top: 60px;
}

.fact-name {
    margin-top: 15px;
    font-size: 18px;
}

</style>
