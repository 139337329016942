<template>
    <div>
        <SiteHeadline :headline="headline" :message="message" :switch-headline="switchHeadline"
                      class="block-headline-distance"/>

        <div uk-grid
             class="section-headline-distance uk-text-center uk-flex uk-flex-center uk-flex-middle uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-2">
            <div v-for="customer in customers" :key="customer.name" class="uk-text-center">
                <div class="uk-padding-small">
                    <img :alt="customer.name" :src="getBaseUrl+'/api/storage/app/'+customer.logo"
                         class="customer-logo" uk-img/>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";

export default {
    name: 'CustomersList',
    components: {SiteHeadline},
    props: {
        headline: String,
        message: String,
        customers: Array,
        switchHeadline: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.customer-list {
    padding: 80px 40px 80px 40px !important;
}

.customer-logo {
    max-height: 90px;
    max-width: 200px;
}

@media (max-width: $breakpoint-small) {
    .customer-logo {
        max-height: 60px;
        max-width: 160px;
    }
}

@media (max-width: 400px) {
    .customer-logo {
        max-height: 40px;
        max-width: 110px;
    }
}

.section-headline-distance {
    margin-top: 60px;
}

</style>
