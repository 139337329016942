<template>
    <div class="social-media-buttons">

        <div
            :class="'uk-grid-match uk-flex uk-flex-center uk-child-width-auto ' + (isDeviceMobile ? 'uk-grid-small' : 'uk-grid')"
            uk-grid>
            <div v-for="social in getConfig.socials" :key="social.name">
                <div>
                    <a :class="'uk-icon-button '+iconButtonSize" :href="social.link"
                       :uk-icon="'icon:'+social.name+'; ratio:'+iconRatio"
                       :uk-tooltip="'title:'+capitalize(social.name)" rel='nofollow'
                       target="_blank"/>
                </div>

            </div>
        </div>
    </div>
</template>


<script>

import {isMobileOnly} from 'mobile-device-detect';

export default {
    name: 'SocialMediaButtons',
    props: {
        iconButtonSize: String,
        iconRatio: Number
    },
    data() {
        return {
            isDeviceMobile: isMobileOnly,
        }
    },
}

</script>


<style lang="scss" scoped>

.uk-icon-button {
    width: 42px !important;
    height: 42px !important;
    color: $alternative-color !important;

    &.large {
        width: 64px !important;
        height: 64px !important;
    }
}

</style>
