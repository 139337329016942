<template>
    <div class="news site-width single uk-animation-fade">
        <Seo :title="$t('newsTitle')" canonical="/news"/>

        <div class="menu-headline-distance"/>

        <div v-if="getMode==='cancelcancer'">
            <SiteHeadline is-main :headline="$t('socialMedia')"
                          :message="$t('socialMediaMessage')"/>
            <div class="headline-item-distance">
                <SocialMediaButtons :iconRatio="1.5" iconButtonSize="large"/>
            </div>
            <div class="section-distance-new"/>


        </div>
        <div v-else>
            <div v-if="getMode ==='camcore'">
                <SiteHeadline is-main :headline="$t('socialMedia')"
                              :message="$t('socialMediaMessage')"/>
                <div class="headline-item-distance">
                    <SocialMediaButtons :iconRatio="1.5" iconButtonSize="large"/>
                </div>
                <div class="section-distance-new"/>
            </div>

            <SiteHeadline v-if="newsItems && newsItems.length>0" :headline="$t('newsTitle')"
                          :message="$t('newsMessage', {x: getConfig.name})"/>

            <LoadingStruct :is-loading="loading" is-animation-disabled>
                <div v-if="newsItems && newsItems.length>0"  class="headline-message-distance"/>
                <div v-if="newsItems && newsItems.length>0" >
                    <div v-if="newsError" class="uk-text-center strong-message">

                        <div class="headline-news white">{{ $t('newsError') }}</div>
                        <Button :action="getNews" :text="$t('tryAgain')" has-action is-large is-min-width
                                mode="primary"/>

                    </div>

                    <div class="uk-grid-large" uk-grid>
                        <div v-for="(newsItem, key) in computedNewsItems" :key="key" class="uk-width-1-1">
                            <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-animation-fade">
                                <div class="uk-grid-collapse" uk-grid>
                                    <div class="uk-width-1-1 uk-height-medium">

                                        <a v-if="newsItem.link" :href="newsItem.link"
                                           :uk-tooltip="'title:'+$t(newsItem.action)"
                                           target="_blank">
                                            <div v-if="isVideo(newsItem.media)"
                                                 class="uk-cover-container uk-height-1-1">
                                                <video :id="'video'+key"
                                                       :src="getBaseUrl+'/api/storage/app/'+newsItem.media"
                                                       muted
                                                       oncontextmenu="return false;" playsinline
                                                       uk-cover="autoplay: inview"></video>
                                                <VideoLoop :id="'video'+key"/>
                                            </div>
                                            <div v-else
                                                 class="uk-cover-container uk-height-1-1">
                                                <img :alt="newsItem.media"
                                                     :src="getBaseUrl+'/api/storage/app/'+newsItem.media"
                                                     uk-cover>
                                            </div>
                                        </a>
                                        <span v-else>
                                    <div v-if="isVideo(newsItem.media)" class="uk-cover-container uk-height-1-1">
                                        <video :id="'video'+key"
                                               :src="getBaseUrl+'/api/storage/app/'+newsItem.media"
                                               muted
                                               oncontextmenu="return false;" playsinline
                                               uk-cover="autoplay: inview"></video>
                                        <VideoLoop :id="'video'+key"/>
                                    </div>
                                    <div v-else
                                         class="uk-cover-container uk-height-1-1">
                                        <img :alt="newsItem.media"
                                             :src="getBaseUrl+'/api/storage/app/'+newsItem.media"
                                             uk-cover>
                                    </div>
                                </span>

                                    </div>
                                    <div class="uk-width-1-1 uk-padding">
                                        <div class="headline-news white">
                                            {{ newsItem['headline' + $i18n.locale.toUpperCase()] }}
                                        </div>
                                        <div class="news-text">{{
                                                newsItem['message' + $i18n.locale.toUpperCase()]
                                            }}
                                        </div>
                                        <div class="uk-margin-large-top"/>

                                        <div class="uk-flex uk-flex-middle uk-grid-small" uk-grid>
                                            <div v-if="newsItem.link" class="button-width">
                                                <a :href="newsItem.link" target="_blank">
                                                    <Button :text="$t('link')" is-large is-max mode="primary"/>
                                                </a>

                                                <div class="uk-hidden@s uk-margin-top"/>
                                            </div>
                                            <div v-if="newsItem.link" class="uk-width-expand"/>
                                            <div v-if="!newsItem.link" class="uk-width-expand uk-visible@s"/>
                                            <div class="uk-width-auto">
                                                <span uk-icon="icon: calendar; ratio: 1.3"/>
                                                {{ convertDate(newsItem.released_at) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="newsLimit < Object.entries(newsItems).length"
                         class="uk-margin-medium-top uk-text-center">
                    <span v-on:click="showMoreNews()">
                        <Button :text="$t('showMoreNews')" is-large is-min-width mode="default"/>
                    </span>
                    </div>


                </div>


                <div v-if="getMode ==='filmpiraten'">
                    <div class="section-distance-new"/>
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200">
                        <SiteHeadline :headline="$t('socialMedia')"
                                      :message="$t('socialMediaMessage')"/>
                        <div class="headline-item-distance">
                            <SocialMediaButtons :iconRatio="1.5" iconButtonSize="large"/>
                        </div>
                    </div>
                </div>

                <div class="last-button-distance"/>
            </LoadingStruct>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "../components/SiteHeadline";
import Seo from "../components/Seo";
import SocialMediaButtons from "../components/SocialMediaButtons";
import axios from 'axios';
import Button from "@/components/Button";
import LoadingStruct from "@/components/LoadingStruct";
import VideoLoop from "@/components/VideoLoop";

export default {
    name: 'News',
    components: {
        VideoLoop,
        LoadingStruct,
        Button,
        SocialMediaButtons,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            newsItems: [],
            newsLimit: 5,
            loading: true,
            newsError: false,
        };
    },
    created() {
        this.getNews();
    },
    computed: {
        computedNewsItems() {
            let newsItems = this.newsItems;
            let limit = this.newsLimit;

            if (!newsItems) {
                return [];
            }

            return Object.entries(newsItems).slice(0, limit).map(entry => entry[1]);
        }
    },
    methods: {
        showMoreNews() {
            let maxNews = this.newsItems.length;
            let newLimit = this.newsLimit + 5;

            if (newLimit > maxNews) {
                newLimit = maxNews;
            }

            this.newsLimit = newLimit;
        },
        isVideo(type) {
            if (type.includes(".mp4")) {
                return true;
            }

            return false;
        },
        getNews() {
            let http = axios.create({
                timeout: this.$store.state.apiTimeout,
            });

            http.get(this.getBaseUrl + '/api/news').then(response => {
                this.newsItems = response.data;
                this.newsError = false;
                this.loading = false;

                this.checkScrollTag();

            }).catch(() => {
                this.newsError = true;
                this.loading = false;
                this.checkScrollTag();

            });
        },
    }
}
</script>


<style lang="scss" scoped>

.news-text {
    font-size: 18px;
    display: -webkit-box;
}

.button-width {
    width: 180px;
}

.headline-news {
    font-size: 24px;
    margin-bottom: 20px;
}

</style>
