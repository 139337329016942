<template>
    <div class="site-headline">

        <div class="headline-struct uk-text-center">
            <div v-if="!switchHeadline && headline">
                <h1 v-if="isMain" :class="'headline uk-padding-remove '+headlineClass" v-html="headline"/>
                <div v-else :class="'headline uk-padding-remove '+headlineClass" v-html="headline"/>
            </div>
            <div>
                <div v-if="!logo">
                    <h1 v-if="isMain && !headline"
                        :class="'message uk-padding-remove uk-margin-remove' + (getMode==='filmpiraten' ? ' secondary' :'')"
                        v-html="message"/>
                    <div v-else
                         :class="'message uk-padding-remove uk-margin-remove' + (getMode==='filmpiraten' ? ' secondary' :'')"
                         v-html="message"/>
                </div>
                <img v-else :alt="message" :src="logo"
                     class="logo" uk-img/>


                <div v-if="buttonMessage || hasButton" class="button-distance">
                    <div v-if="buttonMessage">
                        <router-link v-if="buttonRoute" :to="'/'+$i18n.locale+'/'+buttonRoute">
                            <Button :mode="buttonClass" is-large :text="buttonMessage"/>
                        </router-link>

                        <Button v-else is-large :mode="buttonClass"
                                :uk-toggle="'target: '+buttonHref"
                                :text="buttonMessage"/>
                    </div>
                    <slot/>
                </div>
            </div>
            <div v-if="switchHeadline && headline">
                <div :class="'headline switch uk-padding-remove '+headlineClass" v-html="headline"/>
            </div>
        </div>

    </div>
</template>


<script>

import Button from "@/components/Button.vue";

export default {
    name: 'SiteHeadline',
    components: {Button},
    props: {
        headline: String,
        message: String,
        buttonMessage: String,
        buttonRoute: String,
        buttonClass: String,
        buttonHref: String,
        headlineClass: String,
        hasButton: Boolean,
        switchHeadline: Boolean,
        logo: String,
        isMain: Boolean,
    }
}

</script>


<style lang="scss" scoped>

.headline {
    color: $font-color !important;
    font-weight: 400 !important;
    letter-spacing: .007em !important;
    margin-bottom: 6px !important;

    &:first-letter {
        text-transform: capitalize !important;
    }

    &.reset-transform {
        text-transform: initial !important;
    }

    &.switch {
        margin-bottom: 0 !important;
        margin-top: 8px !important;
    }

    line-height: 1.5!important;
    font-size: 22px !important;
}

.message {
    color: $font-color-hover;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;

    &.secondary {
        color: $fp-color;
    }

    font-size: 32px;
}

.headline-struct {
    padding: 0 20px 0 20px !important;
}

.button-distance {
    margin-top: 30px;
}

.logo {
    width: 280px;
    margin-top: 8px;
}

</style>
