<template>
    <div class="email-contact">

        <SiteHeadline v-if="getMode==='cancelcancer'"
                      :message="$t('email')"/>
        <SiteHeadline v-else :headline="$t('email')"
                      :message="$t('directEmailMessage')"/>
        <div class="headline-item-distance"/>
        <div
            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding uk-text-center site-width single">
            <div class="available-number uk-grid-collapse uk-flex-center uk-grid-match uk-flex uk-flex-middle cursor-pointer"
                 :uk-tooltip="'title:'+$t('copyToClipboard')"
                 v-on:click="$copyText(getConfig.email)"
                 uk-grid>
                <div>
                    <span uk-icon="icon: mail; ratio: 1.3"></span>
                </div>
                <div>
                    <span class="padding-left-small">
                        <span class="location-address padding-left-small">
                            {{ getConfig.email }}
                        </span>
                    </span>
                </div>
            </div>

            <a :href="'mailto:'+getConfig.email">
                <Button :text="$t('sendEmail')" is-large is-min-width mode="primary"/>
            </a>

            <div class="uk-margin-top">
                {{ $t('weWillRespondShortly') }}
            </div>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";
import Button from "@/components/Button";

export default {
    name: 'EmailContact',
    components: {Button, SiteHeadline},
}

</script>


<style lang="scss" scoped>

.location-address {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.4;
    letter-spacing: .007em;
    font-size: 22px;

    &.name {
        font-size: 22px;
        font-weight: 500;
    }
}

.available-info {
    margin-top: 15px;
    font-size: 16px;
}

.available-number {
    margin-bottom: 15px;
}

</style>
